import React from 'react';
import { useState, useEffect, Fragment } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import Autocomplete from '@material-ui/lab/Autocomplete';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';
import SearchIcon from '@material-ui/icons/Search';
import { PartSearchEmail } from '../templates/partsearch_email';
import { SubscribeEmail } from '../templates/subscribe_email';
import swal from '@sweetalert/with-react';
import $ from 'jquery';
import { ParallaxBanner } from 'react-scroll-parallax';
import Container from '@material-ui/core/Container';
var parseString = require('xml2js').parseString;

const GreenCheckbox = withStyles({
    root: {
        color: '#97de1a',
        '&$checked': {
            color: '#97de1a',
        },
    },
    checked: {},
})((props) => <Checkbox color="default" {...props} />);


const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        height: '100%',
        alignItems: 'center',
        '& label.MuiFormLabel-root.MuiInputLabel-shrink': {
            color: theme.palette.secondary.main,
            background: 'rgba(0,0,0,.8)',
            padding: '4px 8px',
            borderRadius: 20,
            transform: 'translate(10px, -10px) scale(0.75)',
            '&.Mui-error': { color: theme.palette.error.main, }
        },
        '& .MuiTextField-root': {
            width: '100%',
            '& .MuiInputBase-root': { background: 'white' },
            '& .MuiInputBase-root.Mui-disabled': { background: 'rgba(144, 144, 144, 1)' },
            '& .MuiFormHelperText-root:not(.Mui-error)': { color: '#97de1a' }
        },
        '& .MuiFormControl-root': {
            '& .MuiInputBase-input': { padding: '12px' },
            '& .MuiFormHelperText-root:not(.Mui-error)': { color: '#97de1a' },
            '& .MuiInputLabel-outlined:not(.Mui-focused):not(.MuiFormLabel-filled)': {
                transform: 'translate(14px, 14px) scale(1)'
            }
        },
        '& .MuiAutocomplete-root': {
            '& .MuiInputBase-input': {
                padding: '4px!important'
            }
        },
        '& .MuiFormGroup-root + .MuiFormHelperText-root': {
            marginTop: -12
        },
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    container: {
        width: '100%',
        maxWidth: '100%',
    },
    maxWidth: {
        borderRadius: 8,
        flexGrow: 1
    },
    endContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        padding: theme.spacing(2),
    },
    middleContainer: {
        display: 'flex',
        alignSelf: 'center'
    },
    center: {
        display: 'flex',
        justifyContent: 'center',
        '& fieldset': { minWidth: 150 }
    },
    outlineBtn: {
        border: '1px solid rgba(151,222,26, .8)',
        color: 'rgba(151,222,26, 1)'
    },
    h4: { color: '#97de1a', fontWeight: '600', textTransform: 'uppercase', fontFamily: 'Saira' },
    banner: {
        color: 'white',
        fontFamily: 'Raleway',
        fontSize: '65px',
        textTransform: 'uppercase',
        letterSpacing: '4px',
        textAlign: 'center',
        fontWeight: 800,
        [theme.breakpoints.down('sm')]: {
            fontSize: 22,
            letterSpacing: 2
        },
    },
    bannerSub: {
        color: '#82c016',
        borderBottom: '2px solid',
    },
    bannerSubSpan: {
        fontFamily: 'Roboto',
        letterSpacing: '.5px',
        fontSize: '1.3em',
        fontWeight: 'normal',
        color: 'white',
        textAlign: 'center',
        [theme.breakpoints.down('sm')]: {
            fontSize: 12
        },
    },
    checkboxLabel: {
        '& .MuiFormControlLabel-label': { color: theme.palette.white.main }
    },
    chkboxErrorTxt: { color: theme.palette.error.main }
}));

const initialStateItemsDefault = {
    value: '',
    inputValue: '',
    list: [],
    disabled: true,
    helperText: '',
    error: false,
    component: 'AutoComplete',
    isEmailField: true,
    isRequired: true,
    md: 4
};

export function getOpts(post) {
    return {
        method: 'POST',
        headers: { 'Content-type': 'application/json', },
        body: JSON.stringify({ post }),
    };
}

export function getHeights(props) {
    let { headerHeight, navHeight } = props;
    if (window.screen.availWidth < 768) navHeight = 0;
    return { navHeight, headerHeight, height: (headerHeight - navHeight) };
}

export const SearchContainer = (props) => {
    const baseUrl = 'https://www.fueleconomy.gov/ws/rest/';
    const classes = useStyles();
    const initialState = {
        Year: { ...initialStateItemsDefault, disabled: false, helperText: 'Please select the Year' },
        Make: { ...initialStateItemsDefault },
        Model: { ...initialStateItemsDefault },
        Options: { ...initialStateItemsDefault },
        'Part Category': { ...initialStateItemsDefault, list: props.data ? Object.keys(props.data.partsList) : [] },
        'Email Address': { ...initialStateItemsDefault, value: '', label: 'Email Address*', id: 'email_address', name: 'email_address', disabled: false, component: 'TextField', helperText: '' },
        'Agreement': { ...initialStateItemsDefault, value: false, label: 'I agree *', disabled: false, component: 'Checkbox', isEmailField: false, helperText: '' },
        'Subscribe': { ...initialStateItemsDefault, value: false, label: 'Subscribe', disabled: false, component: 'Checkbox', isEmailField: false, helperText: '', isRequired: false }
    };
    const submitStyle = {
        true: { // Style if disabled
            background: '#505a3e',
            cursor: 'not-allowed'
        }
    };
    const resetIndex = 5;
    const autoOpts = { method: 'GET' };
    const [searchData, setState] = useState(initialState);

    const validateSearch = () => {
        let count = 0;

        Object.entries(searchData).forEach(([name, data]) => {
            let error = !data.value;
            let helperText = data.helperText || '';

            if (name.toLowerCase().includes('email')) {
                let emailField = $(`#${data.id}`).get(0);
                let isValid = emailField.value && emailField.value.length > 3 && emailField.checkValidity();

                helperText = isValid ? '' : 'Must enter a valid email address';
                error = !isValid;
            }

            if (name.toLocaleLowerCase().includes('agreement')) {
                if (!data.value) helperText = 'Required field';
            }

            if (name.toLowerCase() === 'subscribe') error = false;
            if (error) count++;

            setState(prevState => ({ ...prevState, [name]: { ...prevState[name], error, helperText } }));
        });

        return count;
    };

    const getErrors = () => {
        return Object.values(searchData).filter((data) => data.isRequired && !data.inputValue).length;
    };

    const updateInputValue = (key, inputValue, enableEle, i) => {
        setState(prevState => ({ ...prevState, [key]: { ...prevState[key], inputValue } }));
        if (searchData[key].list.includes(inputValue)) handleChange(key, inputValue, enableEle, i);
    };

    const resetFields = (i, currName, currVal, enableEle, resetAll = false) => {
        let newState = Object.entries(searchData).reduce((prev, curr, ind) => {
            let [key, data] = curr;

            if (ind === i) return { ...prev, [currName]: { ...searchData[currName], value: currVal, inputValue: currVal, error: !currVal } };
            if ((ind > i && ind < resetIndex) || (ind > i && resetAll)) return { ...prev, [key]: { ...initialState[key] } };
            return { ...prev, [key]: data };
        }, {});

        if (enableEle) return { ...newState, [enableEle]: { ...newState[enableEle], disabled: !currVal } }; // Return the new state which enables the field below it
        else return { ...newState };
    };

    const resetAllFields = () => setState(() => ({ ...resetFields(0, 'Year', '', 'Make', true) }));

    const handleChange = (name, value, enableEle, i) => {
        value = value === null ? '' : value; // null values are bad
        let newState = resetFields(i, name, value, enableEle); // If they are reselecting something, reset appropriate fields
        let apiUrl = baseUrl;

        if (value) {

            if (['Year', 'Make', 'Model'].includes(name)) {

                if (name === 'Year') apiUrl += `vehicle/menu/make?year=${value}`; // Need to set the list for the 'Make'
                else if (name === 'Make') apiUrl += `vehicle/menu/model?year=${searchData.Year.value}&make=${value}`; // Need to set the list for the 'Model'
                else apiUrl += `vehicle/menu/options?year=${searchData.Year.value}&make=${searchData.Make.value}&model=${value}`; // Need to set the list for 'Options'

                fetch(encodeURI(apiUrl), autoOpts).then(res => res.text()).then(data => {
                    parseString(data, function (err, result) {
                        let list = result.menuItems.menuItem.map(item => item.text[0]);
                        setState(prevState => ({ ...prevState, ...newState, [enableEle]: { ...newState[enableEle], list } }));
                    });
                });
            }

            else setState(() => ({ ...newState }));
        } // END if (value)

        else setState(() => ({ ...newState }));
    };

    const SendEmail = () => {
        let errors = validateSearch(); // Get all of the fields that aren't filled out

        if (errors < 1) {
            let fields = Object.entries(searchData)
                .filter((val) => val[1].isEmailField)
                .reduce((prev, [k, v]) => ({ ...prev, [k]: v }), {});

            fetch('/api/sendpartsemail', getOpts({ fields, html: PartSearchEmail(fields) }))
                .then(() => {

                    swal({
                        content: <div><h1>Success!</h1>Someone will contact you shortly.</div>,
                        button: 'OK',
                        icon: 'success',
                    });

                    resetAllFields();

                    // Now send a subscribe email if they checked the "subscribe" button
                    if (searchData.Subscribe.value) {
                        let email = searchData['Email Address'].value;
                        fetch('/api/subscribe', getOpts({ email, html: SubscribeEmail(email) }));
                    }
                });
        }
    };

    useEffect(() => {
        try {
            // Get the years
            fetch(`${baseUrl}vehicle/menu/year`, autoOpts).then(res => res.text()).then(data => {
                parseString(data, function (err, result) {
                    let list = result.menuItems.menuItem.map(item => item.value[0]);
                    setState(prevState => ({ ...prevState, Year: { ...prevState.Year, list } }));
                });
            });
        }
        catch (error) { console.error(error); }
    }, []);

    const dataEntries = Object.entries(searchData).filter((val) => val[1].component !== 'Checkbox');
    const checkboxEntries = Object.entries(searchData).filter((v) => v[1].component === 'Checkbox');

    return <Container className={classes.root} maxWidth="md">
        <Grid container spacing={2} direction="row" justifyContent="center" alignItems="center">

            {/* HEADER CONTAINER */}
            <Grid item id="header-container" xs={12} style={{ zIndex: 1 }}>
                <Typography variant="h4" className={classes.banner}>Select Your Vehicle</Typography>
                <Grid container justifyContent="center" alignItems="center">
                    <Grid className={classes.bannerSub} item xs={3} sm={4}></Grid>
                    <Grid className={classes.bannerSubSpan} item xs={6} sm={4}>Search for your car part</Grid>
                    <Grid className={classes.bannerSub} item xs={3} sm={4}></Grid>
                </Grid>
            </Grid>

            {/* SEARCH FIELD CONTAINER */}
            <Grid item>
                <Grid container spacing={1} direction="row" alignItems="flex-start">
                    {dataEntries.map(([name, data], i) => {
                        let enableEle = null;
                        if (((i + 1) <= dataEntries.length) && ['Year', 'Make', 'Model', 'Options'].includes(name)) {
                            try { enableEle = (dataEntries[i + 1])[0]; } // Then get the next element to disable
                            catch (err) { }
                        }

                        let FieldComponent = <Autocomplete
                            key={`combo-${name}`}
                            id={`combo-${name}`}
                            openOnFocus
                            options={data.list}
                            getOptionLabel={(option) => option}
                            onChange={(e, newValue) => handleChange(name, newValue, enableEle, i)}
                            inputValue={data.inputValue}
                            onInputChange={(e, newInputValue) => { updateInputValue(name, newInputValue, enableEle, i); }}
                            disabled={data.disabled}
                            renderOption={(option) => {
                                let pre = (name === 'Part Category' && props.data.partsList[option]) || '';
                                return <Fragment>{option}{pre && <Chip style={{ marginLeft: 8 }} size="small" label={pre} color="secondary" />}</Fragment>;
                            }}
                            renderInput={(params) => {
                                let pre = (name === 'Part Category' && props.data && props.data.partsList[params.inputProps.value]) ? ` (${props.data.partsList[params.inputProps.value]})` : '';
                                return <TextField
                                    {...params}
                                    inputProps={{ ...params.inputProps, value: params.inputProps.value + pre }}
                                    key={i}
                                    id={name}
                                    name={name}
                                    label={`${name}*`}
                                    error={data.error}
                                    // helperText={data.disabled || data.value ? '' : `Please select the ${name}`}
                                    variant="outlined" />;
                            }}
                        />;

                        if (data.component === 'TextField') {
                            FieldComponent = <TextField type="email" id={data.id} label={data.label} placeholder={data.label}
                                value={data.value}
                                name={data.name}
                                onChange={(e) => handleChange(name, e.target.value, enableEle, i)}
                                variant="outlined"
                                error={data.error}
                                helperText={data.helperText}
                            />;
                        }

                        return <Grid key={`${name}-${i}`} item xs={12} md={data.md}>{FieldComponent}</Grid>;
                    })}
                </Grid>
            </Grid>

            {/* CHECKBOXES CONTAINER */}
            <Grid item xs={12}>
                <Grid container justifyContent='center'>
                    <Grid item xs={12}></Grid>
                    {checkboxEntries.map(([name, data], i) => {
                        let enableEle = null;
                        return <Grid item xs={6} md={2} key={`checkbox-${name}`}>
                            <FormControl required error={!data.value} component="fieldset">
                                <FormGroup>
                                    <FormControlLabel
                                        className={classes.checkboxLabel}
                                        control={<GreenCheckbox
                                            color="secondary"
                                            checked={data.value}
                                            onChange={() => { handleChange(name, !(data.value), enableEle, i + (resetIndex + 1)); }} name={data.name}
                                        />}
                                        label={<span style={{ color: data.helperText && !data.value ? '#fc9e11' : 'white' }}>{data.label}</span>}
                                    />
                                </FormGroup>
                                <FormHelperText>{!data.value && data.helperText}</FormHelperText>
                            </FormControl>
                        </Grid>;
                    })}
                </Grid>
            </Grid>

            {/* SUBMIT + RESET BUTTONS */}
            <Grid item xs={12}>
                <Grid container spacing={2} alignItems="center">
                    <Grid item><Button variant="contained" color="secondary" size="large" onClick={SendEmail} endIcon={<SearchIcon />} style={submitStyle[getErrors() > 0]}>Search</Button></Grid>
                    <Grid item><Button className={classes.outlineBtn} variant="outlined" color="default" size="large" onClick={resetAllFields} disableElevation>Reset</Button></Grid>
                </Grid>
            </Grid>

        </Grid>
    </Container>;
};

export const Header = (props) => {
    const pb = <ParallaxBanner
        className="your-class"
        layers={[
            {
                image: 'img/intro-bg.jpg',
                amount: 0.7,
            },
        ]}
        style={{
            height: window.innerHeight - (64 + 30) // 500 // 
        }}
    >
        <SearchContainer data={props.data} />
    </ParallaxBanner>;
    return (
        <>{pb}</>
    );
};