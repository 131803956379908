export const Policy = ({ policy }) => {
  let policyDict = {
    'privacy': <div>
      <h1>Privacy Statement</h1>
      <div>
        <p>
          Afpauto Online and its affiliates respect your concerns about privacy and your
          right to control the dissemination and other processing of your personal
          information. References in this Privacy Notice (the “Notice”) to “Afpauto
          Online”, “we”, “us”, and “our” are references to the entity responsible for the
          processing of your personal information, which generally is the entity that
          obtains your personal information.
        </p>
        <p>
          This Notice applies to the personal information we collect through our
          websites, mobile applications, social media pages, and offline collection
          methods that reference this Notice (collectively, the “Service”).
        </p>
        <p>
          This Notice describes how the Service collects information from you, what types
          of information it collects, how we may use the personal information, with whom
          we may share it, the measures we take to safeguard the personal information we
          obtain, how you may exercise your rights regarding our processing of your
          personal information, and how you can contact us regarding our privacy
          practices.
        </p>
        <p>
          The Service may provide links to other third-party websites and features, or
          contain third-party cookies, that are not owned or controlled by us. Please
          review the privacy notices of these third parties to familiarize yourself with
          their privacy practices.
        </p>
        <p>
          By using the Service, you acknowledge you have read and understood the terms of
          this Notice. Please fully review this Notice before you use the Service or
          submit information to us.
        </p>

        <ol>
          <li><span className="list-1">PERSONAL INFORMATION WE COLLECT</span>
            <p> The types of personal information we may collect through the Service include:</p>
            <ol>
              <li>Contact information including name, phone and fax number, email and postal
                address for you or for others (e.g., principals in your business);</li>
              <li>Information used to create your online account (such as username, password and
                security question and answer);</li>
              <li>We collect and store the information that
                you and other users provide when you and they use and interact with the Service
                (such as your favorite store locations, your vehicles, and other information
                you provide when using the Service);</li>
              <li>Purchase and customer service
                history;</li>
              <li>Payment information, including name, billing address, payment
                card details (i.e., card number, expiration date and security code) and bank
                account information;</li>
              <li>Location data such as data derived from your IP
                address, country and zip code;</li>
              <li>Usage data including details on your
                browser (such as type, version, language); operating system and interface;
                website from which you are visiting us (referrer URL); webpage(s) you are
                visiting on our website; date and time of accessing our website and time zone
                difference; access status/HTTP status code; volume of data transferred;
                internet protocol (IP) address; and information gathered via cookies;</li>
              <li>Correspondence-related data such as emails, texts, letters, or responses to
                surveys and questionnaires; information you provide us by phone or live chat;
                or your feedback on your experience with the Service, or an Afpauto facility;
                and</li>
              <li>Other personal information contained in content you submit to us (such
                as through our “Contact Us” feature, which may include your first name, last
                name, mobile number, e-mail, your favorite store location, and your message).
                We use third-party web analytics services in connection with the Service,
                including Google Analytics, which use cookies and similar technologies to
                collect data (such as IP addresses) to evaluate use of and interaction with the
                Service. Please note that providing personal information to us is voluntary on
                your part. If you choose not to provide us certain information, we may not be
                able to offer you certain products and services, and you may not be able to
                access certain features of the Service.</li>
            </ol>
          </li>
          <li><span className="list-1">COOKIES AND SIMILAR TECHNOLOGIES</span>
            <p>Like most companies, Afpauto Online uses
              cookies and similar technologies in the Service to personalize and enhance your
              experience with our Service. We and third-parties with whom we partner
              (including Google Analytics) may use cookies, web beacons, tracking pixels, web
              bugs, scripts, tags, and other similar technology (collectively, “Cookies”) to
              record information about your activities on our websites and on third-party
              websites that you visit.</p>
            <ol>
              <li>Cookies. A cookie is a small text file that is stored on a device for record-keeping purposes. Cookies
                record information about your activities while using the Websites and “remember” you when you return to the
                Websites. Some cookies remain on your device until you delete them.
                <p>
                  Others, like session ID cookies, expire when you close your browser. You may set your device settings to
                  attempt to reject Cookies, but doing so may affect functionality of the websites. Information collected by
                  cookies may include, but is not limited to: your IP address, browser type and language, device type, device
                  identifiers, other device and software information (such as configuration information on your device and
                  other applications), apps you access, search and browsing information, web usage information, referring/exit
                  pages and URLs, other browser history, platform type, number of clicks, landing pages, cookie information,
                  the pages you requested and viewed, the amount of time spent on particular pages and the date and time of
                  each request you make.
                </p>
              </li>
              <li>Pixels. Pixels (aka web beacons) are tiny graphics with a unique identifier that are used to track the
                online movements of web users. Unlike Cookies, which are stored on a computer’s hard drive, pixels are small
                graphics that are about the size of the period at the end of the sentence that are embedded invisibly on web
                pages or in HTML-based emails. We do not control the use of pixels by third-parties.
                <p>
                  Unless you disable the Cookies, by using the Service you agree to our use of those Cookies.
                </p>
              </li>
              <li>Making cookie choices. You can control how your browser handles Cookies received from our websites. You can
                choose to refuse all cookies, or to be prompted before a cookie is saved to your hard drive, or to only accept
                Cookies from certain websites that you designate. Information on deleting or controlling Cookies is available
                at www.AboutCookies.org. By refusing to accept Cookies from us, you may not be able to use some of the
                features and functionality available on our website.</li>
              <li>Sharing tools. To enhance your experience when using the Service, we sometimes embed content and sharing
                tools from other third-party sites, such as “like” buttons from social networking sites; these third-party
                sites may also place and access cookies on your device. Afpauto Online does not control the placement or
                access of these third-party cookies. Please review the privacy policies of these third parties to familiarize
                yourself with their practices.</li>
              <li>Google Analytics. Google, a third-party analytics provider, collects information about our website usage and
                users of the Service. Google may use cookies and pixels to collect usage information from users that visit the
                Service, including information about the pages where users enter and exit the Service, what pages users view
                on the Service, time spent on each page, browser version, operating system, internet protocol address, and
                geolocation information. These technologies allow Google to recognize a user when a user visits the Service
                and when the user visits other websites. Google aggregates the information it collects from the Service and
                from other websites and shares the aggregated information with us and other website operators, including but
                not limited to age range, gender, geographic regions, general interests and details about devices used to
                visit websites and purchase items. For more information regarding Google’s collection methods and use of
                information, see the Google Privacy Policy by visiting the following link:
                https://www.google.com/intl/en/policies/privacy/. If you would like to opt out of Google Analytics tracking,
                please visit the following link: https://tools.google.com/dlpage/gaoptout.
              </li>
              <li>Online tracking and your choices. As discussed above, because we and our third-party service providers
                automatically collect Service usage information through the use of Cookies, your selection of the “Do Not
                Track” option provided by your browser may not have any effect on our collection of automatically collected
                information. One way to attempt to “opt out” of the collection of any information through Cookies and some
                (but not all) other tracking technology is to actively manage the settings on your browser to delete and
                disable Cookies and other tracking/recording tools. However, getting a “Do Not Track” signal to work as you
                might want is difficult and may not be possible. Using browsers as an example, not all tracking technologies
                can be controlled by browsers; unique aspects of your browser might be recognizable even if you disable a
                tracking technology; not all settings will necessarily last or be effective; even if a setting is effective
                for one purpose, data still may be collected for another; and even if one website observes a “Do Not Track”
                signal, that website usually will not be able to control other websites. Additionally, if you select a “Do Not
                Track” option or disable Cookies on your device, certain Service may become unavailable or may not function
                properly.</li>
            </ol>
          </li>
          <li><span className="list-1">HOW WE USE PERSONAL INFORMATION</span>
            <p></p>
            <ol>
              <li>Provide and administer our products and services;</li>
              <li>Process and fulfill orders and keep you informed about the status of your order;</li>
              <li>Communicate about and administer our products, services, events, programs and promotions (such as by sending
                alerts, promotional materials, newsletters and other marketing communications);</li>
              <li>Conduct and facilitate surveys, sweepstakes, contests, focus groups and market research initiatives;</li>
              <li>Perform data analytics (such as market research, trend analysis, financial analysis and customer
                segmentation);</li>
              <li>Provide customer support;</li>
              <li>Process, evaluate and respond to requests, inquiries and applications;</li>
              <li>Create, administer and communicate with you about your account (including any purchases and payments);</li>
              <li>Provide investor services;</li>
              <li>Conduct marketing and sales activities (including generating leads, pursuing marketing prospects, performing
                market research, determining and managing the effectiveness of our advertising and marketing campaigns and
                managing our brand);</li>
              <li>Operate, evaluate and improve our business (such as by administering, enhancing, and improving our products
                and services; developing new products and services; managing our communications and customer relationships;
                and performing accounting, auditing, billing, reconciliation and collection activities);</li>
              <li>Verify your identity and protect against and prevent fraud and other unlawful activity, unauthorized
                transactions, claims and other liabilities, and manage risk exposure and quality;</li>
              <li>Conduct investigations and comply with and enforce applicable legal requirements, relevant industry
                standards, contractual obligations and our policies and terms; and</li>
              <li>Maintain and enhance the safety and security of our products, services, information resources and employees.
              </li>
              <div>We may anonymize or aggregate personal information and use it for the purposes described above and for other
                purposes to the extent permitted by applicable law. We may also use personal information for additional
                purposes that we identify at the time of collection. We will obtain your consent for these additional uses to
                the extent required by applicable law.</div>
              <div>
                Where required by applicable law, we will obtain your consent for the processing of your personal information
                for direct marketing purposes.
              </div>
              <div>
                The legal basis for Afpauto Online processing your personal information as described above will typically be
                one of the following: (1) your consent; (2) performance of a contract with you or a relevant party; (3) our
                legitimate business interest; or (4) compliance with our legal obligations.</div>
            </ol>
          </li>
          <li><span className="list-1">HOW WE SHARE INFORMATION</span>
            <p>
              We may share your personal information to (internal and external) recipients for the processing purposes
              described above.
            </p>
            <ol>
              <li>Within Afpauto Online: Depending on the categories of personal information and the purposes for which the
                personal information has been collected, different internal departments within Afpauto Online may receive it
                (for example, our IT department may have access to usage data; our sales departments may have access to
                account and order data). Moreover, other Afpauto Online departments may have access to certain personal
                information about you on a need to know basis, such as the legal department, the finance department or
                internal auditing.</li>
              <li>Sharing with Other Users. The Service may allow users to connect and communicate with others, such as
                through a message board. If you use any communication features, other users of the Service may be able to view
                your profile information and your communications that you post to the Service.</li>
              <li>Service Providers. We use third parties to help us operate and improve the Service (e.g., hosting providers,
                e-commerce service providers and analytics service providers). We may provide these third parties with
                information we collect and they may collect information from you and about your use of the Service. The
                processing by these third parties will, unless specifically noted otherwise in this Notice, be governed by a
                contractual agreement requiring them to process the personal information solely on behalf of Afpauto Online
                when performing the Service, including maintaining or servicing accounts, providing customer service,
                processing or fulfilling orders and transactions, verifying customer information, processing payments,
                providing financing, providing advertising or marketing services, providing analytic services, or providing
                similar services on behalf of Afpauto Online.</li>
              <li>Insolvency and Business Transitions. If we ever file for bankruptcy or engage in a business transition such
                as a merger or joint venture with another company, or if we purchase, sell, or reorganize all or a part of the
                Service or our business or assets or the business or assets of our affiliates, we may disclose your personal
                information, including to prospective or actual purchasers in connection with one of these transactions.
              </li>
              <li>Regulators, authorities and other third parties. We may also transfer your personal information to
                governmental agencies and regulators (e.g. tax authorities), courts, government authorities, and independent
                external advisors acting as controllers (e.g., lawyers, accountants, auditors etc.) all in accordance with
                applicable law.</li>
            </ol>
          </li>
          <li><span className="list-1">DATA TRANSFERS</span>
            <p>
              We may transfer the personal information we collect and may store such information outside the country in which
              the information was provided. These countries may have different data protection laws than the country in which
              the information was provided. If we do so, we will take necessary measures to ensure that such transfers are
              adequately protected as required by applicable data protection law, including Standard Contractual Clauses
              approved by the European Commission.
            </p>
          </li>
          <li><span className="list-1">HOW WE PROTECT PERSONAL INFORMATION</span>
            <p>We maintain administrative, technical and physical safeguards, consistent with legal requirements where the
              personal information was obtained, designed to protect against unlawful or unauthorized destruction, loss,
              alteration, use or disclosure of, or access to, the personal information provided to us through the Service.</p>
          </li>
          <li><span className="list-1">YOUR RIGHTS</span>
            <p>
              If you have an account with us and would like to update your personal information associated with your account,
              you may make changes in the “My Profile” or “Settings” portions of the Service. Aside from updates you make, at
              any time, you may challenge the accuracy or completeness of your personal information in our records. If you
              successfully demonstrate that your personal information in our records is inaccurate or incomplete, we will
              update the personal information as required. Where appropriate, we will transmit the updated information to
              third parties having access to your personal information.
            </p>
            <p>
              If you would like to cancel your Account, request access or a correction to your personal information (other
              than corrections you can make in the “My Profile” or “Settings” portions of the Service), please contact us
              using the “Contact Us” feature within the Service. If you do not want your information accessed or stored as
              described in this Notice, you should not access, use, or register an Account with the Service.
            </p>
            <p>
              To the extent provided by the law of your jurisdiction, you may also have the following rights:
            </p>
            <ol>
              <li>Right to withdraw your consent. If you have given your consent regarding certain types of processing
                activities (in particular regarding the receipt of certain direct marketing communications), you can withdraw
                this consent at any time with future effect. Such a withdrawal will not affect the lawfulness of the
                processing prior to the consent withdrawal. You can withdraw your consent by clicking on the withdraw option
                in relevant communication or by contacting your relationship manager (for existing customers).</li>
              <li>Right to request access to your personal data. If provided by applicable data protection law, you have the
                right to obtain from us confirmation as to whether or not personal data concerning you is processed, and,
                where that is the case, to request access to the personal data. The access information includes – inter alia –
                the purposes of the processing, the categories of personal data concerned, and the recipients or categories of
                recipients to whom the personal data have been or will be disclosed. However, this is not an absolute right
                and the interests of other individuals may restrict your right of access. You also have the right to obtain a
                copy of the personal data undergoing processing free of charge.</li>
              <li>Right to request rectification. Where provided by applicable data protection law, you have the right to
                obtain from us the rectification of inaccurate personal data concerning you. Depending on the purposes of the
                processing, you have the right to have incomplete personal data completed, including by means of providing a
                supplementary statement.</li>
              <li>Right to request erasure (right to be forgotten). Where provided by applicable data protection law, you have
                the right to obtain from us the erasure of personal data concerning you and we are obliged to erase such data.
              </li>
              <li>Right to request restriction of processing. Where provided by applicable data protection law, you have the
                right to obtain from us restriction of processing of your personal information and we are obliged to restrict
                such processing. In this case, the respective personal information will be marked and may, with the exception
                of storage, only be processed by us with your consent or for certain purposes.</li>
              <li>Right to request data portability. Where provided by applicable data protection law, you have the right to
                receive the personal data concerning you, which you have provided to us, in a structured, commonly used and
                machine-readable format, and you have the right to transmit those personal data to another entity without
                hindrance from us, where the processing is carried out by automated means and is based on consent pursuant.
              </li>
              <li>Right to object. Where provided by applicable data protection law and under certain circumstances, you have
                the right to object, on grounds relating to your particular situation, at any time to the processing of your
                data by us and we are required to no longer process your data. Such right to object especially applies if we
                collect and process your data for direct marketing purposes. If you have a right to object and if you exercise
                this right, your data will no longer be processed for such purposes by us. Such a right to object does not
                exist if the processing of your data is necessary to take steps prior to entering into a contract or to
                perform a contract already concluded.
              </li>
              <div>
                To exercise your rights, please contact us by using the contact information at the end of this Notice.
                Additionally, to the extent the European Union General Data Protection Regulation (GDPR) applies, you also
                have the right to lodge a complaint with the competent data protection supervisory authority in the relevant
                Member State (e.g., the place where you reside, work, or where an alleged infringement of the GDPR took
                place).
              </div>
            </ol>
          </li>
          <li><span className="list-1">DATA RETENTION</span>
            <p>
              Your personal information will be retained as long as necessary to provide you with the services and products
              requested. Once you have terminated the contractual relationship with us and/or you have deleted your Account,
              we will remove your personal data from our systems and records and/or take steps to properly anonymize it so
              that you can no longer be identified from it (unless we need or have a right to keep your information to comply
              with legal or regulatory obligations to which Afpauto Online is subject – e.g., taxation purposes – or to meet
              our legitimate interests).
            </p>
          </li>
          <li><span className="list-1">ADDITIONAL INFORMATION</span>
            <p></p>
            <ol>
              <li>Children. The Service is intended for users over the age of 18 and is not directed to children under 13. We
                do not knowingly collect personal information from children under 13. If you become aware that a child has
                provided us with personal information without parental consent, please contact us by using the contact
                information at the end of this Notice, and we will take steps to remove the information and terminate the
                child’s account.</li>
              <li>California Residents.
                <p>
                  a) may contact us by using the contact information at the end of this Notice. California Civil Code Section
                  1798.83 permits California residents to request and obtain from us once a year, free of charge, a list of
                  the third parties to whom we have disclosed personal information (as defined under that statute) of that
                  California resident, for direct marketing purposes in the preceding calendar year and the categories of that
                  kind of personal information that was disclosed to them. If you are a California resident and you wish to
                  make such a request, you may contact us by using the contact information at the end of this Notice.
                </p>
                <p>
                  b) While the Service is not intended for anyone under the age of 18, if you are a California resident under
                  age 18 and you believe your information has become publicly-available through the Service and you are unable
                  to remove it, you may request removal by contacting us at: support@afpauto.com. When requesting removal, you
                  must be specific about the information you want removed and provide us with specific information, such as
                  the URL for each page where the information is located, so that we can find it. Removal of your content or
                  information from the Service does not ensure complete or comprehensive removal of that content or
                  information from our systems or the systems of our service providers. We are not required to delete the
                  information posted by you; our obligations under California law are satisfied so long as we anonymize the
                  information or render it invisible to other users and the public.
                </p>
              </li>
              <li>Modification of this Notice. We will occasionally update this Notice, in our sole discretion. When we post
                changes to this Notice, we will revise the “Last updated date” at the top of this Notice in order to notify
                you of changes. We recommend that you check the Service from time to time to inform yourself of any changes in
                this Notice or any of our other policies. If you do not agree to any update, please do not use the Service; by
                continuing to access or use the Service after a change to this Notice becomes effective, you agree to and
                accept the revised Notice as of the Notice Effective Date.</li>
            </ol>
          </li>

          <li><span className="list-1">HOW TO CONTACT US</span>
            <p>If you have questions, concerns or complaints regarding your existing account please contact us using the
              “Contact Us” feature within the Service.</p>
            <p>
              If you have questions, concerns or complaints about our privacy practices, please contact our Privacy Office at:
            </p>
            <p>
              <b>support@afpauto.com</b>
            </p>
            <p>
              or
            </p>
            <p><b>
              Afpauto Online<br />
              9300 Airline Drive.<br />
              Houston, TX 77037<br />
              Attn: Chief Privacy Officer
            </b>
            </p>
            <p>
              or
            </p>
            <p>
              <b>
                Privacy Office<br />
                (281) 820-1313
              </b>
              US Toll Free
            </p>
          </li>
        </ol>
      </div>
    </div>,
    'terms': <div>
      <h1>Terms and Conditions</h1>
      <div>
        <h3 id="introduction">Introduction</h3>
        <p>Afpauto Online (hereinafter "Afpauto Online"), provides this website solely for the purpose of providing information about Afpauto Online and the products (as herein defined) we offer.</p>
        <h4 id="i-acceptance-of-terms-and-conditions-of-use">I. Acceptance of Terms and Conditions of Use</h4>
        <p>Except as otherwise set forth herein or agreed to between you and Afpauto Online, these terms and conditions (the "Terms and Conditions") govern your use of the Afpauto.com website, (the "Site") and any products purchased from Afpauto Online using the Site (the "Products"). By accessing and using the Site, you acknowledge you have read, understand and unconditionally agree to follow and be bound by these Terms and Conditions, and your continued use of the Site is contingent upon such agreement.</p>
        <h4 id="ii-revisions-to-terms-and-conditions">II. Revisions to Terms and Conditions</h4>
        <p>Afpauto Online reserves the right, at any time, to revise these Terms and Conditions without prior notice by updating this posting. Your continued use of the Site following any posting of changes to these Terms and Conditions signifies that you agree to be bound by any revisions we may make.</p>
        <h4 id="iii-terms-and-conditions-disclaimer">III. Terms and Conditions Disclaimer</h4>
        <p>Although these Terms and Conditions have been prepared with the intent to provide reliable information, no warranty (express or implied) is made as to their accuracy or completeness and Afpauto Online assumes no liability for loss or damage resulting from reliance on these Terms and Conditions.</p>
        <h4 id="iv-use-of-site">IV. Use of Site</h4>
        <p>The Site, including without limitation all products, information, content, and services made available on or through the Site, is provided "as is." Afpauto Online and its affiliates, employees and agents make no representations or warranties of any kind or nature whatsoever regarding the content or services of the Site. Afpauto Online and its affiliates, employees and agents disclaim any and all express or implied conditions, representations, and warranties, including but not limited to warranties related to any course of dealing, usage, or trade practice and any implied warranty of merchantability, fitness for a particular purpose, title, or non-infringement.</p>
        <p>Afpauto Online does not represent or warrant that your access to the Site will be uninterrupted or error-free, that defects will be corrected, or that the Site and materials accessible to you are free of viruses or other harmful components.</p>
        <p>This Site is intended for use by individuals 13 years of age or older. Individuals under the age of 13 must have the assistance and permission of a parent or guardian to use the Site.</p>
        <p>All materials (collectively, the "Content") included on the Site are the property of Afpauto Online, its affiliates or content providers of Afpauto Online, and are protected by copyright and trademark law. The Content is intended solely for your own personal, non-commercial use. You may download or copy the Content for your personal use. However, the act of downloading or copying any of the Content shall not transfer to you any right, title, or interest in the Content. You may not reproduce, publish, transmit, distribute, display, modify, create derivative works from, sell, or participate in any sale of or exploit in any way, in whole or in part, any of the Content without the prior written consent of Afpauto Online. You are prohibited from modifying, transmitting, distributing, reusing, reverse engineering, reposting, or "framing," the Content without the express written permission from an authorized representative of Afpauto Online.</p>
        <p>Afpauto Online claims no proprietary rights to, or affiliation with, any third-party trademarks, logo references, or product images appearing on the Site. Such marks and images are used solely to designate certain products or services as belonging to their respective owners.</p>
        <p>The Content published to the Site is subject to change without notice. While Afpauto Online strives to provide up-to-date and accurate content and information on the Site, it is your sole responsibility to confirm the accuracy, completeness, or reliability of the Content. Afpauto Online does not warrant or make any representation regarding the use of the Content or its accuracy or reliability.</p>
        <h4 id="v-your-user-information">V. Your User Information</h4>
        <p>In the event that any of the services on this Site require you to open an account ("your Account") or otherwise provide user or registration information ("your User Information"), you must complete the registration process by providing us with complete, truthful, and accurate information. You grant to us and to all other persons and entities involved in the operation of this Site the right to use, store, monitor, retrieve, and transmit your User Information in connection with the operation of this Site. Our information collection and use policies with respect to your User Information are set forth in our Privacy Policy, which is incorporated in these Terms and Conditions by reference for all purposes.</p>
        <p>You are solely responsible for maintaining the confidentiality of your User Information. You are also solely responsible for any and all activities that occur under your Account or your User Information. You must notify us immediately of any suspected or actual unauthorized use of your Account or your User Information.</p>
        <p>We reserve the right to terminate your Account or to refuse services to you, without prior notice to you, at any time and for any or no reason. Without limiting the above, we will permanently terminate your Account and remove your User Information from this Site if you have been found to violate Afpauto Online Online's copyrights, or those of any other copyright holder; if we believe that you may be under 13 years of age; or if you violate these Terms and Conditions. If your Account, or your registration(s) with or ability to access this Site, or any other service provided to you by this Site is discontinued by us on any of the foregoing bases, you agree that you shall not attempt to re-register with or access this Site or any other service provided by this Site, through use of a different username or registration information.</p>
        <h4 id="vi-links">VI. Links</h4>
        <p>Afpauto Online may provide links to other websites not associated with Afpauto Online. Afpauto Online has not reviewed, nor shall it be responsible for linked websites or any of their content, and Afpauto Online makes no representations regarding the content of any linked website or any companies that own, control, or manage the linked websites. Consequently, Afpauto Online cannot be held responsible for the accuracy, relevancy, copyright compliance, legality, or decency of material contained in, programs used by, or services or products offered by websites linked to from the Site.</p>
        <h4 id="vii-site-security">VII. Site Security</h4>
        <p>You are prohibited from violating or attempting to violate the security of the Site, including, without limitation, (a) accessing data not intended for you or logging onto a server or an account you are not authorized to access; (b) attempting to probe, scan, or test the vulnerability of a system or network, or to breach security or authentication measures without proper authorization; (c) attempting to interfere with service to any user, host, or network, including, without limitation, via means of submitting a virus to the Site, overloading, "flooding," "spamming," "mailbombing," or "crashing;" (d) sending unsolicited email, including promotions and/or advertisements of products or services; or (e) forging any TCP/IP packet header or any part of the header information in any email or newsgroup posting. Violations of system or network security will result in the user being denied access to the Site and may result in civil or criminal liability. Afpauto Online will use commercially reasonable efforts to investigate occurrences that may involve such violations and will cooperate with law enforcement authorities in prosecuting Site users who are involved in such violations. You agree not to use any device, software, or routine to interfere or attempt to interfere with the proper working of the Site or any activity being conducted on the Site. You agree not to use or attempt to use any engine, software, tool, agent, or other device or mechanism (including without limitation browsers, spiders, robots, avatars, intelligent agents, or similar devices) to search or navigate the Site other than the search engine and search agents available from Afpauto Online on the Site and other than generally available third-party web browsers.</p>
        <p>Any passwords used for the Site are only intended for individual use. You are prohibited from sharing or otherwise providing the right to use your password with any other person. You are responsible for the security of any password. Afpauto Online may, in its sole discretion, monitor the use of any password and may require you to change your password at any time.</p>
        <h4 id="viii-product-images">VIII. Product Images</h4>
        <p>Afpauto Online uses electronic images in reference to certain products we make available for sale through the Site. Afpauto Online does not warrant that any particular product image on display fully and completely represents the exact appearance and makeup of that associated product. Product images may represent different iterations of available products, which may differ in terms of exact appearance, available features, design, or configuration. Products' particular colorings may differ slightly from their appearance in product images displayed on the Site. Afpauto Online reserves the right to display "boilerplate," generic, or otherwise representational images in reference to available products.</p>
        <h4 id="ix-payment-and-order-acceptance">IX. Payment and Order Acceptance</h4>
        <p>Prices for all Products are displayed in United States dollars and exclude any and all applicable taxes and shipping and handling charges, unless expressly stated otherwise. You are responsible for the payment of any applicable taxes, whether or not they are listed on your receipt or statement. If you purchase Products, you agree to pay, using a valid credit card (or other form of payment that Afpauto Online accepts), the applicable fees and taxes (if any) set forth in the offer you accepted. Afpauto Online reserves the right, upon prior notice to you, to change the amount of any fees and to institute new fees.</p>
        <p>Afpauto Online reserves the right, in its sole discretion, to refuse or cancel any order for any reason. Afpauto Online may also require additional verification or information before accepting any order. All authorized charges will be billed to your designated credit card account (or other payment method), on the terms described in the specific offer. If payment cannot be charged to your credit card or your payment is returned to Afpauto Online for any reason, Afpauto Online reserves the right to either suspend or terminate your access to the Site and/or the unpaid-for Products.</p>
        <p>It is your responsibility to ensure that sufficient funds are available to cover the charges for the Products. Afpauto Online has no liability for any overdraft or other fees that you may incur as a result of Afpauto Online processing your payment. Afpauto Online will contact you if all or any portion of your order is cancelled or if additional information is required to accept your order. If your order is cancelled after your credit card has been charged, Afpauto Online will issue a credit to you in the amount of the charge.</p>
        <h4 id="x-product-safety">X. Product Safety</h4>
        <p>Automotive parts, equipment, and accessories require careful and proper use, installation, inspection, and maintenance for proper performance. Afpauto Online is not responsible and will not be held liable for any harm or damages you may incur as the result of improper, illegal, unsafe, or otherwise incorrect use, installation, inspection, or fitment of any product you purchase from the Site.</p>
        <h4 id="xi-limitation-of-liability">XI. Limitation of Liability</h4>
        <p>Afpauto Online and its affiliates will not be liable for any direct, indirect, incidental, punitive, consequential, or any other damages whatsoever that result from the use or performance of, or the inability to use, the Site, the Products, or information, or functions on such Site, even if Afpauto Online has been advised of the possibility of such damages. In no event will Afpauto Online and its affiliates' total liability to you for all damages, losses, and causes of action, whether arising out of contract, tort, or otherwise, exceed the amount paid by you, if any, for accessing the Site or purchasing the Products.</p>
        <h4 id="xii-termination-of-access">XII. Termination of Access</h4>
        <p>Afpauto Online may, without prior notice to you, immediately terminate the Agreement or revoke any or all of your rights granted under this Agreement, in addition to any other legal or equitable remedies for misuse. Upon any termination of this Agreement, you shall immediately cease all access to and use of the Site and Afpauto Online shall, in addition to any other legal or equitable remedies, immediately revoke all password(s) and account identification issued to you and deny your access to and use of this Site in whole or in part. Any termination of this Agreement shall not affect the respective rights and obligations (including without limitation, payment obligations) of the parties arising before the date of termination.</p>
        <h4 id="xiii-entire-agreement">XIII. Entire Agreement</h4>
        <p>These Terms and Conditions embody the entire agreement and understanding between the parties and supersedes all prior agreements and understandings relating to the subject matter hereof.</p>
        <h4 id="xiv-severability">XIV. Severability</h4>
        <p>If any these provisions shall be deemed invalid, void, or for any reason unenforceable, that condition shall be deemed several and shall not affect the validity and enforceability of any remaining provision.</p>
        <h4 id="xv-survival">XV. Survival</h4>
        <p>Any provision of these Terms and Conditions shall be or become invalid or unenforceable, such invalidity or unenforceability shall not invalidate or render unenforceable the remaining provisions of these Terms and Conditions. Instead, this entire Agreement shall be construed as though not containing the particular invalid or unenforceable provision or provisions, and the rights and obligations of the parties shall be construed and enforced accordingly.</p>
        <h4 id="xvi-indemnification">XVI. Indemnification</h4>
        <p>You agree to defend, indemnify, and hold harmless Afpauto Online and its affiliates, employees and agents from and against any and all claims, damages, costs, and expenses, including without limitation, attorneys' fees, arising from or related to your use of the Site or the Products.</p>
        <h4 id="xvii-remedies">XVII. Remedies</h4>
        <p>The remedy of Afpauto Online at law for any actual or threatened breach of these Terms and Conditions would be inadequate and thus, Afpauto Online will be entitled to specific performance or injunctive relief, or both, in addition to any damages that Afpauto Online is legally entitled to recover, together with reasonable expenses of any form of dispute resolution, including, without limitation, attorneys' fees. No right or remedy of Afpauto Online shall be exclusive of any other, whether at law or in equity, including without limitation, damages, injunctive relief, attorneys' fees, and expenses. No waiver by Afpauto Online of its rights or remedies under these Terms and Conditions shall imply any obligation to grant any similar, future, or other waiver.</p>
        <h4 id="xviii-applicable-law-and-arbitration">XVIII. Applicable Law and Arbitration</h4>
        <p>By visiting the Site, you agree that the laws of Texas, without regard to principles of conflict of laws, will govern these Terms and Conditions and that any dispute of any sort that might arise between you and Afpauto Online. The venue of any arbitration between you and Afpauto Online arising out of conflict pertaining to these Terms and Conditions shall be the State of Texas, County of Harris.</p>
      </div>
    </div>,
    'shipping': <div>
      <h1>Shipping and Delivery Policy</h1>
      <div>
        <h3 id="when-can-i-expect-delivery-of-my-order">When can I expect delivery of my order?</h3>
        <p>You can expect most packages to be delivered within five to 10 business days.</p>
        <p>We process your order within 24 business hours after receipt of full payment, and items will leave our facilities within two - three business days after processing.</p>
        <p>We provide you with tracking information via email 24 to 48 hours after your item has shipped. Please ensure you have provided us with a valid email address, and check your spam or junk folder as communications from Afpauto Online may not make it past your filter, depending on your settings.</p>
        <p>If after 10 business days you haven't received your order, please contact our shipping department at <a href="mailto:ops@afpauto.com">ops@afpauto.com</a>.</p>
        <h3 id="why-was-my-order-delayed">Why was my order delayed?</h3>
        <p>Your order may be delayed if we are missing information necessary for processing. If this is the case, we will email you within 48 hours of receiving your order. Please check your spam or junk folder as communications from Afpauto Online may not make it past your filter, depending on your settings.</p>
        <p>If you have not received an email from us and have not received your order after 10 days, please contact our shipping department at <a href="mailto:ops@afpauto.com">ops@afpauto.com</a>.</p>
        <p>Remember that we cannot ship to a post office box or an APO. If you order a freight item (such as seats, engines, transmissions, running boards, or bumpers), we must have your business name, physical address, and daytime phone number.</p>
        <h3 id="will-there-be-a-handling-fee-for-my-order">Will there be a handling fee for my order?</h3>
        <p>Yes. The handling fee covers packaging materials needed to provide the safe arrival of your purchase. We typically include this fee with the shipping charge.</p>
        <h3 id="can-i-arrange-my-own-shipping">Can I arrange my own shipping?</h3>
        <p>No. We use very carefully selected commercial carriers to provide the best US coverage and care for your products. We therefore do not allow customers to arrange for their own shipping. By letting us package items, select the best shipping method, and coordinate freight pick-ups, you are guaranteed the most secure and efficient shipping course at the best possible price.</p>
        <h3 id="will-you-ship-freight-items-to-canada-or-elsewhere-outside-the-us">Will you ship freight items to Canada or elsewhere outside the US?</h3>
        <p>Afpauto Online will only ship to the continental 48 United States. At this time, we cannot ship to Hawaii, Alaska, Canada, Mexico, Puerto Rico, or elsewhere overseas.</p>
        <h3 id="what-shipping-methods-does-afpauto-online-use">What shipping methods does Afpauto Online use?</h3>
        <p>We ship more than 75 percent of our items via UPS Ground service. We do not use the United States Postal Service, and therefore cannot ship to a post office box or APO address. Occasionally, we ship ground packages via FedEx. Large or oversized items (motors, transmissions, seats, wheels, bumpers, and tires) will ship via LTL Freight Carriers.</p>
        <h3 id="how-will-you-ship-my-engine-bumper-seat-or-other-large-item">How will you ship my engine, bumper, seat, or other large item?</h3>
        <p>For large items such as those mentioned above, we ship via less-than-truckload (LTL) freight carriers.</p>
        <p>The costs to ship these types of items begin at $100 and can exceed $1,000 for complete engines with transmissions or transaxles. In most cases, we place and band these items on 4x4 or 6x6 pallets, and cover or wrap them.</p>
        <p>We must ship these parts to a commercial address with docking and forklift capabilities. If you do not have a location such as this available to you, we can ship the items to the closest freight terminal to your ZIP code.</p>
        <p>For an additional $75 charge, we will ship to a commercial address without a loading dock or to a residential address using a delivery truck with a lift gate. Your products will be unloaded only at the curb. We cannot unload onto a driveway or into a garage. Please make arrangements to move your items from the curb to a secure location.</p>
        <p>Upon delivery of your order, you should immediately inspect the cartons for any damage that may have occurred during shipment. Carefully inspect the packaging as well as its contents. It is normal for the carton to show some wear.</p>
        <p>The shipping charge does not include removal of any pallets, cartons, or other packaging materials.</p>
        <p>The freight trucking company is not responsible for unloading the items from the truck, or carrying the parts into your building. Your item is likely to be heavy and large, and therefore we recommend having a forklift or other help unloading and moving the item. The total shipping charge does not include inside delivery or removal of cartons.</p>
        <h3 id="why-does-it-cost-so-much-to-ship-airbags">Why does it cost so much to ship airbags?</h3>
        <p>Airbags are considered hazardous materials by the U.S. Department of Transportation, and therefore require special packaging and care to ship properly and safely.</p>
        <p>Up to two airbags can be combined for shipping if they ship from the same location. We cannot combine shipping if multiple airbags in one order ship from different locations. Airbags do not include the module, unless otherwise stated.</p>
        <h3 id="i-live-in-new-york-state-why-cant-i-buy-a-used-airbag-from-you">I live in New York State. Why can't I buy a used airbag from you?</h3>
        <p>Due to a New York State law banning the installation of used airbag equipment in vehicles, Afpauto cannot ship airbags to residents of the State of New York.</p>
        <p>We also cannot ship airbags to any location outside the continental United States, including Alaska, Canada, Hawaii, and Puerto Rico. No air shipments of airbags are allowed.</p>
        <p>To learn more about HAZMAT items and the reasons for the high cost of shipping an airbag, visit <a href="http://www.dot.gov">www.dot.gov</a>.</p>
        <h3 id="why-does-the-color-of-the-part-i-ordered-not-match-my-car">Why does the color of the part I ordered not match my car?</h3>
        <p>We sell used OEM auto parts from various makes and models, with varying color shadings and changes. Color differences may result from such factors as environmental effects and degrees of wear. Therefore we cannot guarantee a part that will fit your vehicle will also be an accurate color match. We recommend consulting with an automotive painting professional to match the used part to your vehicle’s color. We also cannot accept returned parts that have been painted after purchase.</p>
        <h3 id="free-shipping-terms-and-conditions">Free Shipping Terms and Conditions</h3>
        <p>Free ground shipping applies to orders shipped to address in the continental United States and excludes Alaska and Hawaii.  Shipping to a Post Office box or APO address is not available.  Free Shipping excludes wheels, transmissions, axels and any product that ships via freight. Afpauto Online is not responsible for return shipping costs. Offer cannot be combined with any other offer, discount, special pricing or promotion.  Afpauto Online reserves the right to cancel or modify this offer at any time without notice.</p>
      </div>
    </div>,
    'returns': <div>
      <h1>Returns and Refunds Policy</h1>
      <div><h3 id="how-do-i-return-a-part-how-do-i-get-refund">How do I return a part? How do I get refund?</h3>
        <p>As a part of our money-back guarantee, we want you to be completely satisfied with your purchase. If you wish to return a part, please follow the return policy guidelines below.</p>
        <p>Email <a href="mailto:ops@afpauto.com">ops@afpauto.com</a> or call (281) 820-1313 Monday through Thursday, 9:30 a.m. to 4:00 p.m. Centeral Time to request a return authorization (RA) number. We cannot process returns that do not include an RA number.</p>
        <h3 id="damaged-shipments">Damaged Shipments</h3>
        <p>It is extremely important that you inspect your shipment thoroughly before accepting and signing for the merchandise. Even though the box and packaging may be intact, it is possible the product could have arrived damaged. If part does arrive damaged, please accept the delivery and contact Customer Service immediately at (281) 820-1313. Proof of damage may be provided by photos.</p>
        <h3 id="freight-shipments-and-ups-ground-shipments">Freight shipments and UPS Ground shipments</h3>
        <h5 id="freight-shipments">Freight Shipments</h5>
        <p>Before accepting delivery, check for both internal and external damage. Remove the product from its packaging and inspect for damage. If you notice damage, please call our customer service immediately at (281) 820-1313  after accepting the delivery to obtain a return authorization number.</p>
        <p>For Engine assemblies, please be advised that we do not warranty or guarantee any attached accessory parts in the sale, such as switches, sensors, cables, electronics, belts, hoses, water pumps, and manifolds. Because such parts are not warrantied, refusal of delivery for these parts being damaged will result in deduction of original shipping and handling charges from any refund.</p>
        <h5 id="returning-defective-or-incorrect-parts">Returning defective or incorrect parts</h5>
        <p>If you received an incorrect or defective part, you may return it for an exchange or replacement. Please contact Customer Service to explain the problem. We will create a new order for the part and issue an RA for the original part.</p>
        <p>Afpauto Online will ship the replacement part as soon as freight charges have been paid for the returned part and the part is on its way back to us. </p>
        <h3 id="refused-shipment-or-failed-delivery">Refused Shipment or Failed Delivery</h3>
        <p>If you have refused delivery of your shipment, please contact Customer Service immediately at (281) 820-1313. Shipped items that are either refused by the customer or are unable to be delivered due to continual failed delivery attempts will be returned to Afpauto Online, and you will be credited the amount of the sale, not including shipping and handling. Please allow up to 14 business days for a credit to be issued. It is your responsibility to make immediate and adequate arrangements with the shipping company to accept a shipment in the event of a failed delivery.</p>
        <h3 id="afpauto-online-discretion">Afpauto Online Discretion</h3>
        <p>Afpauto Online reserves the right to deny any exchange or return request. Please do not install damaged or install incorrectly shipped items, as doing so will void our return policy. Any parts that have been modified, installed, or damaged in any way by the customer may result in denial of a refund and/or exchange request.</p>
        <p>Customer Service will provide you with the shipping address to send the returned item. You must include the RA number on the shipping label.
          Returned parts must be in "as new" condition and shipped in their original packages and shipping cartons. If the original carton is not available, the part must be packaged correctly to ensure safe shipping. We cannot issue a credit for items that return to our facility damaged, or for items that have been partially or fully installed or painted. Proper shipping and associated cost is the responsibility of the customer.
          The original invoice, or a copy, must be included with the item.
          Our return policy provides 30 days from the date of the original invoice to return items.
          Refunds will be issued once we have received, inspected, and processed the part(s). Original shipping charges will be deducted from refund amount. Exceptions will apply for parts that were received damaged or stocked incorrectly.
          All refunds will be processed once merchandise has been returned to Afpauto . Please allow 5-7 business days for the refund to be posted to your account.</p>
      </div>
    </div>
  };

  return policyDict[policy];
  // return 
};