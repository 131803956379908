export const PartSearchEmail = (props) => {
    let fieldHtml = Object.entries(props).map(([key, val]) => {
        return `
            <tr style="box-sizing: border-box;">
            <td class="label" style="box-sizing: border-box; font-weight: bold; min-width: 150px; padding: 12px;">${key}:</td>
            <td style="box-sizing: border-box; padding: 12px; font-weight: 300; width: 100%;">${val.value || val}</td>
            </tr>
            `;
    }).join('');


    return `<body style="box-sizing: border-box;">
  <table class="tRoot" style="box-sizing: border-box; width: 100%; height: 100%; background: #f0f1f3;" width="100%"
    height="100%">
    <tbody style="box-sizing: border-box;">
      <tr style="box-sizing: border-box;">
        <td class="skinny" style="box-sizing: border-box; padding: 0px; margin: 0px;">
          <table class="tSubRoot" style="box-sizing: border-box; width: 75%; height: 100%; margin: 0px auto 0px auto;"
            width="75%" height="100%">
            <tbody style="box-sizing: border-box;">
              <tr style="box-sizing: border-box;">
                <td class="skinny" style="box-sizing: border-box; padding: 0px; margin: 0px;">
                  <table class="tContainer"
                    style="box-sizing: border-box; width: 100%; height: 100%; font-family: 'Roboto', sans-serif; border-collapse: collapse;"
                    width="100%" height="100%">
                    <tbody class="tBody" style="box-sizing: border-box; margin: 0px 24px;">
                      <tr class="tLogoRow center" style="box-sizing: border-box; text-align: center; height: 140px;"
                        align="center">
                        <td colspan="3" style="box-sizing: border-box; padding: 40px 0 10px 0;"><img
                            src="cid:logo@nodemailer.com"
                            style="box-sizing: border-box; width: 201px; height: 71px;"></td>
                      </tr>
                      <tr class="accentBg"
                        style="box-sizing: border-box; background: linear-gradient(to right, #97DE1A 0%, #66CC33 100%);">
                        <td class="tHeader title v-bottom" colspan="2"
                          style="box-sizing: border-box; padding: 48px; height: 80px; color: white; font-size: 60px; font-weight: 700; padding-bottom: 0px; vertical-align: bottom;"
                          height="80" valign="bottom">
                          <span style="box-sizing: border-box;">Hello</span>
                        </td>
                        <td class="headerImg" rowspan="2"
                          style="box-sizing: border-box; padding: 48px 24px; text-align: right; position: relative;">
                          <img
                            src="cid:emailimg@nodemailer.com"
                            style="box-sizing: border-box;padding-right: 24px;position: absolute;top: 20;right: 10;max-width: 400px;width: 30vw;">
                        </td>
                      </tr>
                      <tr class="accentBg"
                        style="box-sizing: border-box; background: linear-gradient(to right, #97DE1A 0%, #66CC33 100%);">
                        <td class="subtitle"
                          style="box-sizing: border-box; padding: 48px; color: white; font-size: 22px; font-weight: 400; height: 70px; padding-top: 0px; vertical-align: top;"
                          height="70" valign="top">You have a request for a part!</td>
                      </tr>
                      <tr class="tRow" style="box-sizing: border-box; padding: 8px; background: white;">
                        <td class="v-top" colspan="3"
                          style="box-sizing: border-box; padding: 48px; vertical-align: top;" valign="top">
                          A customer has requested parts with the following details:

                          <table class="dt"
                            style="box-sizing: border-box; background: #f0f1f3; border-radius: 8px; margin: 40px 0 0 0; font-size: 14px; padding: 16px;">
                            ${fieldHtml}

                    </tbody>
                  </table>
                </td>
              </tr>
              <tr class="tRow center"
                style="box-sizing: border-box; text-align: center; padding: 8px; background: white;" align="center">
                <td class="footer" colspan="3"
                  style="box-sizing: border-box; padding: 0px; background: #272c34; color: white; font-weight: 200; font-size: 11px; height: 50px;"
                  height="50">
                  <table align="center" border="0" cellpadding="0" cellspacing="0" width="100%"
                    class="templateContainer"
                    style="border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;max-width: 600px !important;">
                    <tbody>
                      <tr>
                        <td valign="top" class="footerContainer"
                          style="background:transparent none no-repeat center/cover;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;background-color: transparent;background-image: none;background-repeat: no-repeat;background-position: center;background-size: cover;border-top: 0;border-bottom: 0;padding-top: 0;padding-bottom: 0;">
                          <table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnFollowBlock"
                            style="min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                            <tbody class="mcnFollowBlockOuter">
                              <tr>
                                <td align="center" valign="top"
                                  style="padding: 9px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;"
                                  class="mcnFollowBlockInner">
                                  <table border="0" cellpadding="0" cellspacing="0" width="100%"
                                    class="mcnFollowContentContainer"
                                    style="min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                                    <tbody>
                                      <tr>
                                        <td align="center"
                                          style="padding-left: 9px;padding-right: 9px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                                          <table border="0" cellpadding="0" cellspacing="0" width="100%"
                                            style="min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;"
                                            class="mcnFollowContent">
                                            <tbody>
                                              <tr>
                                                <td align="center" valign="top"
                                                  style="padding-top: 9px;padding-right: 9px;padding-left: 9px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                                                  <table align="center" border="0" cellpadding="0" cellspacing="0"
                                                    style="border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                                                    <tbody>
                                                      <tr>
                                                        <td align="center" valign="top"
                                                          style="mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                                                          <table align="left" border="0" cellpadding="0" cellspacing="0"
                                                            style="display: inline;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                                                            <tbody>
                                                              <tr>
                                                                <td valign="top"
                                                                  style="padding-right: 10px;padding-bottom: 9px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;"
                                                                  class="mcnFollowContentItemContainer">
                                                                  <table border="0" cellpadding="0" cellspacing="0"
                                                                    width="100%" class="mcnFollowContentItem"
                                                                    style="border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                                                                    <tbody>
                                                                      <tr>
                                                                        <td align="left" valign="middle"
                                                                          style="padding-top: 5px;padding-right: 10px;padding-bottom: 5px;padding-left: 9px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                                                                          <table align="left" border="0" cellpadding="0"
                                                                            cellspacing="0" width=""
                                                                            style="border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                                                                            <tbody>
                                                                              <tr>

                                                                                <td align="center" valign="middle"
                                                                                  width="24"
                                                                                  class="mcnFollowIconContent"
                                                                                  style="mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                                                                                  <a href="https://www.facebook.com/4starautoparts"
                                                                                    target="_blank"
                                                                                    style="mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;"><img
                                                                                      src="https://cdn-images.mailchimp.com/icons/social-block-v2/outline-light-facebook-48.png"
                                                                                      alt="Facebook"
                                                                                      style="display: block;border: 0;height: auto;outline: none;text-decoration: none;-ms-interpolation-mode: bicubic;"
                                                                                      height="24" width="24"
                                                                                      class=""></a>
                                                                                </td>


                                                                              </tr>
                                                                            </tbody>
                                                                          </table>
                                                                        </td>
                                                                      </tr>
                                                                    </tbody>
                                                                  </table>
                                                                </td>
                                                              </tr>
                                                            </tbody>
                                                          </table>
                                                          <table align="left" border="0" cellpadding="0" cellspacing="0"
                                                            style="display: inline;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                                                            <tbody>
                                                              <tr>
                                                                <td valign="top"
                                                                  style="padding-right: 10px;padding-bottom: 9px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;"
                                                                  class="mcnFollowContentItemContainer">
                                                                  <table border="0" cellpadding="0" cellspacing="0"
                                                                    width="100%" class="mcnFollowContentItem"
                                                                    style="border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                                                                    <tbody>
                                                                      <tr>
                                                                        <td align="left" valign="middle"
                                                                          style="padding-top: 5px;padding-right: 10px;padding-bottom: 5px;padding-left: 9px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                                                                          <table align="left" border="0" cellpadding="0"
                                                                            cellspacing="0" width=""
                                                                            style="border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                                                                            <tbody>
                                                                              <tr>

                                                                                <td align="center" valign="middle"
                                                                                  width="24"
                                                                                  class="mcnFollowIconContent"
                                                                                  style="mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                                                                                  <a href="mailto:support@afpauto.com"
                                                                                    target="_blank"
                                                                                    style="mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;"><img
                                                                                      src="https://cdn-images.mailchimp.com/icons/social-block-v2/outline-light-forwardtofriend-48.png"
                                                                                      alt="Email support"
                                                                                      style="display: block;border: 0;height: auto;outline: none;text-decoration: none;-ms-interpolation-mode: bicubic;"
                                                                                      height="24" width="24"
                                                                                      class=""></a>
                                                                                </td>


                                                                              </tr>
                                                                            </tbody>
                                                                          </table>
                                                                        </td>
                                                                      </tr>
                                                                    </tbody>
                                                                  </table>
                                                                </td>
                                                              </tr>
                                                            </tbody>
                                                          </table>
                                                          <table align="left" border="0" cellpadding="0" cellspacing="0"
                                                            style="display: inline;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                                                            <tbody>
                                                              <tr>
                                                                <td valign="top"
                                                                  style="padding-right: 0;padding-bottom: 9px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;"
                                                                  class="mcnFollowContentItemContainer">
                                                                  <table border="0" cellpadding="0" cellspacing="0"
                                                                    width="100%" class="mcnFollowContentItem"
                                                                    style="border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                                                                    <tbody>
                                                                      <tr>
                                                                        <td align="left" valign="middle"
                                                                          style="padding-top: 5px;padding-right: 10px;padding-bottom: 5px;padding-left: 9px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                                                                          <table align="left" border="0" cellpadding="0"
                                                                            cellspacing="0" width=""
                                                                            style="border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                                                                            <tbody>
                                                                              <tr>

                                                                                <td align="center" valign="middle"
                                                                                  width="24"
                                                                                  class="mcnFollowIconContent"
                                                                                  style="mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                                                                                  <a href="https://www.ebay.com/usr/allforeignparts"
                                                                                    target="_blank"
                                                                                    style="mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;"><img
                                                                                      src="https://cdn-images.mailchimp.com/icons/social-block-v2/outline-light-link-48.png"
                                                                                      alt="Ebay"
                                                                                      style="display: block;border: 0;height: auto;outline: none;text-decoration: none;-ms-interpolation-mode: bicubic;"
                                                                                      height="24" width="24"
                                                                                      class=""></a>
                                                                                </td>


                                                                              </tr>
                                                                            </tbody>
                                                                          </table>
                                                                        </td>
                                                                      </tr>
                                                                    </tbody>
                                                                  </table>
                                                                </td>
                                                              </tr>
                                                            </tbody>
                                                          </table>
                                                        </td>
                                                      </tr>
                                                    </tbody>
                                                  </table>
                                                </td>
                                              </tr>
                                            </tbody>
                                          </table>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>

                                </td>
                              </tr>
                            </tbody>
                          </table>
                          <table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnDividerBlock"
                            style="min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;table-layout: fixed !important;">
                            <tbody class="mcnDividerBlockOuter">
                              <tr>
                                <td class="mcnDividerBlockInner"
                                  style="min-width: 100%;padding: 0 18px 18px 18px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                                  <table class="mcnDividerContent" border="0" cellpadding="0" cellspacing="0"
                                    width="100%"
                                    style="min-width: 100%;border-top: 2px solid #505050;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                                    <tbody>
                                      <tr>
                                        <td
                                          style="mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                                          <span></span>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                          <table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnTextBlock"
                            style="min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                            <tbody class="mcnTextBlockOuter">
                              <tr>
                                <td valign="top" class="mcnTextBlockInner"
                                  style="padding-top: 9px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                                  <table align="left" border="0" cellpadding="0" cellspacing="0"
                                    style="max-width: 100%;min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;"
                                    width="100%" class="mcnTextContentContainer">
                                    <tbody>
                                      <tr>

                                        <td valign="top" class="mcnTextContent"
                                          style="padding-top: 0;padding-right: 18px;padding-bottom: 9px;padding-left: 18px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;word-break: break-word;color: #FFFFFF;font-family: Roboto;font-size: 12px;line-height: 150%;text-align: center;">

                                          <em>Copyright ©2021 AFPAuto.com, All rights
                                            reserved.</em>
                                          <br>
                                          <br>
                                          <strong>Our mailing address is:</strong>
                                          <br>
                                          9300 Airline Dr, Houston, TX 77037
                                          <br>
                                          <br>
                                          No longer want to receive emails?<br>
                                          You can <a href="${window.location.protocol}//${window.location.hostname}${window.location.hostname === 'localhost' ? ':9100' : ''}/api/unsubscribe/jimmy@afpauto.com"
                                          style="mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;color: #FFFFFF;font-weight: normal;text-decoration: underline;">unsubscribe
                                          from this list</a>.
                                          <br>
                                          <br>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
              <tr style="box-sizing: border-box;">
                <td class="spacer" style="box-sizing: border-box; padding: 0; height: 24px; margin: 0;" height="24">
                </td>
              </tr>
            </tbody>
          </table>
        </td>
      </tr>
    </tbody>
  </table>
  </td>
  </tr>
  </tbody>
  </table>
</body>`;





    //     return `<body style="box-sizing: border-box;">
    // <table class="tRoot" style="box-sizing: border-box; width: 100%; height: 100%; background: #f0f1f3;" width="100%" height="100%">
    //     <tbody style="box-sizing: border-box;">
    //         <tr style="box-sizing: border-box;">
    //             <td class="skinny" style="box-sizing: border-box; padding: 0px; margin: 0px;">
    //                 <table class="tSubRoot" style="box-sizing: border-box; width: 75%; height: 100%; margin: 0px auto 0px auto;" width="75%" height="100%">
    //                     <tbody style="box-sizing: border-box;">
    //                         <tr style="box-sizing: border-box;">
    //                             <td class="skinny" style="box-sizing: border-box; padding: 0px; margin: 0px;">
    //                                 <table class="tContainer" style="box-sizing: border-box; width: 100%; height: 100%; font-family: 'Roboto', sans-serif; border-collapse: collapse;" width="100%" height="100%">
    //                                     <tbody class="tBody" style="box-sizing: border-box; margin: 0px 24px;">
    //                                         <tr class="tLogoRow center" style="box-sizing: border-box; text-align: center; height: 140px;" align="center">
    //                                             <td colspan="3" style="box-sizing: border-box; padding: 40px 0 10px 0;"><img src="cid:logo@nodemailer.com" style="box-sizing: border-box;"></td>
    //                                         </tr>
    //                                         <!-- <tr class="tTitleRow center">
    //         <td><span class="afp">All Foreign Parts</span> <span class="auto">AUTO</span></td>
    //     </tr> -->
    //                                         <tr class="accentBg" style="box-sizing: border-box; background: #66CC33;">
    //                                             <td class="tHeader title v-bottom" colspan="2" style="box-sizing: border-box; padding: 48px 24px; height: 80px; color: white; font-size: 40px; font-weight: 700; padding-bottom: 0px; vertical-align: bottom;" height="80" valign="bottom">
    //                                                 <span style="box-sizing: border-box;">Hello</span>
    //                                             </td>
    //                                             <td class="headerImg" rowspan="2" style="box-sizing: border-box; padding: 48px 24px; text-align: right"><img src="cid:emailimg@nodemailer.com" style="box-sizing: border-box; padding-right: 24px"></td>
    //                                         </tr>
    //                                         <tr class="accentBg" style="box-sizing: border-box; background: #66CC33;">
    //                                             <td class="subtitle" style="box-sizing: border-box; padding: 48px 24px; color: white; font-size: 22px; font-weight: 300; height: 70px; padding-top: 0px; vertical-align: top;" height="70" valign="top">You have a request for a part!</td>
    //                                         </tr>
    //                                         <tr class="tRow" style="box-sizing: border-box; padding: 8px; background: white;">
    //                                             <td class="v-top" colspan="3" style="box-sizing: border-box; padding: 48px 24px; vertical-align: top;" valign="top">
    //                                                 A customer has requested parts with the following details:

    //                                                 <table class="dt" style="box-sizing: border-box; background: #f0f1f3; border-radius: 8px; margin: 12px; font-size: 14px;">
    //                                                     ${fieldHtml}
    //                                                     </tbody>
    //                                                 </table>
    //                                             </td>
    //                                         </tr>
    //                                         <tr class="tRow center" style="box-sizing: border-box; text-align: center; padding: 8px; background: white;" align="center">
    //                                             <td class="footer" colspan="3" style="box-sizing: border-box; padding: 0px; background: #272c34; color: white; font-weight: 200; font-size: 11px; height: 50px;" height="50">
    //                                                 © 2021 Mitchell & Valad Consulting. Design by Null-Terminator,
    //                                                 Mitchell &
    //                                                 Valad
    //                                                 Consulting
    //                                             </td>
    //                                         </tr>
    //                                         <tr style="box-sizing: border-box;">
    //                                             <td class="spacer" style="box-sizing: border-box; padding: 0; height: 24px; margin: 0;" height="24"></td>
    //                                         </tr>
    //                                     </tbody>
    //                                 </table>
    //                             </td>
    //                         </tr>
    //                     </tbody>
    //                 </table>
    //             </td>
    //         </tr>
    //     </tbody>
    // </table>
    // </body>`
};


