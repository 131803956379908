import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import MoreIcon from '@material-ui/icons/MoreVert';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import CallIcon from '@material-ui/icons/Call';
import ScheduleIcon from '@material-ui/icons/Schedule';
import { navStyles, getNavLinks } from './util';

export const PrimarySearchAppBar = ({ logoSrc }) => {
  const classes = navStyles();
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const handleMobileMenuClose = () => setMobileMoreAnchorEl(null);
  const handleMobileMenuOpen = (event) => setMobileMoreAnchorEl(event.currentTarget);
  const mobileMenuId = 'primary-search-account-menu-mobile';
  const navLinkArr = getNavLinks({ classes });
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      {navLinkArr('Mobile').map((navLink, i) => <MenuItem key={i}>{navLink}</MenuItem>)}
    </Menu>
  );

  return (
    <>
      <div className={classes.grow}>
        <Toolbar className={classes.topBanner}>
          <Container className={classes.topBarContainer} maxWidth="lg">
            <Grid container className={classes.grow} spacing={3} justifyContent="flex-end" alignItems="center">
              <Grid item>
                <Grid container alignItems="center">
                  <Grid item><CallIcon className={classes.topBannerIcon} /></Grid>
                  <Grid item><span className={classes.label}>Call us:</span> (281) 820-1313</Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Grid container alignItems="center">
                  <Grid item><ScheduleIcon className={classes.topBannerIcon} /></Grid>
                  <Grid item><span className={classes.label}>MON-SAT:</span> 8:00AM-6:00PM</Grid>
                </Grid>
              </Grid>
            </Grid>

          </Container>
        </Toolbar>
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="open drawer">
              <img className={classes.bannerLogo} src={logoSrc} height='40' alt="Logo banner" />
            </IconButton>
            <Typography className={classes.title} variant="h6" noWrap>ALL <span style={{ color: 'white', fontWeight: '600' }}>FOREIGN</span> PARTS</Typography>
            {/* <div className={classes.search}>
            <div className={classes.searchIcon}>
              <SearchIcon />
            </div>
            <InputBase
              placeholder="Search…"
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
              inputProps={{ 'aria-label': 'search' }}
            />
          </div> */}
            <div className={classes.grow} />

            {/* DESKTOP SCREEN NAV LINKS */}
            <div className={classes.sectionDesktop}>{navLinkArr().map(NavLink => NavLink)}</div>

            { /* MOBILE SCREEN MENU */}
            <div className={classes.sectionMobile}>
              <IconButton aria-label="show more" aria-controls={mobileMenuId} aria-haspopup="true" onClick={handleMobileMenuOpen} color="inherit">
                <MoreIcon />
              </IconButton>
            </div>

          </Toolbar>
        </AppBar>
        {renderMobileMenu}
      </div>
      <div className={classes.appBarOffset} />
    </>
  );
};

export const Navigation = (props) => {
  let logoSrc = (props && props.data && props.data.logo_white) || '';
  return <PrimarySearchAppBar logoSrc={logoSrc} />;
};
