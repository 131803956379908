import { alpha, makeStyles } from '@material-ui/core/styles';
import { SocialProvider, SocialLink } from '@mui-treasury/components/socialLink';
import Button from '@material-ui/core/Button';

export const getNavLinks = ({ classes }) => {
    const socialLinksArr = [
        { brand: 'Facebook', href: 'https://www.facebook.com/4starautoparts' },
        { brand: 'Envelope', href: '#contact' },
        { brand: 'Ebay', href: 'https://www.ebay.com/usr/allforeignparts' }
    ];

    const pageLinksArr = [
        { page: 'Search', href: '#page-top' },
        { page: 'Sell', href: '#sell' },
        { page: 'About', href: '#about' },
        { page: 'Contact', href: '#contact' }
    ];

    const navLinks = (classType = '') => pageLinksArr
        .map(({ page, href }, i) => <Button key={`btn-${i}`} className={`${classes[`navLinks${classType}`]} page-scroll`} color="primary" href={href}>{page}</Button>)
        .concat(
            <SocialProvider key="social-provider" useStyles={socialLinkStyles} >
                {socialLinksArr.map(({ brand, href }, ii) => (<SocialLink key={`social-btn-${ii}`} brand={brand} href={href} />))}
            </SocialProvider>
        );

    return navLinks;
};

export const navStyles = makeStyles((theme) => {
    const topBarHeight = 30;
    return {
        grow: { flexGrow: 1 },
        menuButton: { marginRight: theme.spacing(2), },
        appBar: { position: 'fixed', top: topBarHeight },
        appBarOffset: { height: theme.mixins.toolbar.minHeight + topBarHeight + 8 },
        navLinks: {
            color: 'white',
            '&:after': {
                display: 'block',
                position: 'absolute',
                left: 0,
                bottom: 0,
                width: 0,
                height: 2,
                background: 'linear-gradient(to right, #66CC33 0%, #97DE1A 100%)',
                content: '""',
                transition: 'width 0.2s',
            },
            '&:hover': { color: 'white', background: 'transparent' },
            '&:hover:after': { width: '100%' },
            '&:focus': { color: theme.palette.secondary.main }
        },
        navLinksMobile: {
            '& span': { justifyContent: 'flex-start' },
            '&:focus': { color: theme.palette.secondary.dark },
            '&:after': {
                display: 'block',
                position: 'absolute',
                left: 0,
                bottom: 0,
                width: 0,
                height: 2,
                background: 'linear-gradient(to right, #66CC33 0%, #97DE1A 100%)',
                content: '""',
                transition: 'width 0.2s',
            },
            menuSocialLinksMobile: { color: theme.palette.secondary.dark },
            '&:hover': { background: 'transparent', color: 'inherit' },
            '&:hover:after': { width: '100%' }
        },
        title: {
            display: 'none',
            fontFamily: 'Saira',
            fontWeight: 'normal',
            color: theme.palette.secondary.main,
            fontSize: 17,
            [theme.breakpoints.up('sm')]: {
                display: 'block',
            },
        },
        search: {
            position: 'relative',
            borderRadius: theme.shape.borderRadius,
            backgroundColor: alpha(theme.palette.common.white, 0.15),
            '&:hover': {
                backgroundColor: alpha(theme.palette.common.white, 0.25),
            },
            marginRight: theme.spacing(2),
            marginLeft: 0,
            width: '100%',
            [theme.breakpoints.up('sm')]: {
                marginLeft: theme.spacing(3),
                width: 'auto',
            },
        },
        searchIcon: {
            padding: theme.spacing(0, 2),
            height: '100%',
            position: 'absolute',
            pointerEvents: 'none',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        inputRoot: { color: 'inherit', },
        inputInput: {
            padding: theme.spacing(1, 1, 1, 0),
            // vertical padding + font size from searchIcon
            paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
            transition: theme.transitions.create('width'),
            width: '100%',
            [theme.breakpoints.up('md')]: {
                width: '20ch',
            },
        },
        sectionDesktop: {
            display: 'none',
            [theme.breakpoints.up('md')]: {
                display: 'flex',
            },
        },
        sectionMobile: {
            display: 'flex',
            [theme.breakpoints.up('md')]: {
                display: 'none',
            },
        },
        topBanner: {
            minHeight: 20,
            padding: '.5em 0em',
            justifyContent: 'flex-end',
            background: theme.palette.secondary.light,
            color: '#484a47',
            fontFamily: 'Roboto',
            fontWeight: 600,
            fontSize: 12,
            position: 'fixed',
            width: '100%',
            zIndex: 2

        },
        topBannerIcon: {
            fontSize: 13,
            marginRight: 3,
            marginTop: -2
        },
        topBarContainer: { textAlign: 'right', marginTop: 2 },
        label: { color: '#2f8688', textTransform: 'uppercase', fontWeight: 500, },
        bannerLogo: { display: 'inline-flex', }
    };
});

export const socialLinkStyles = makeStyles(({ palette }) => {
    return {
        anchor: {
            display: 'inline-flex',
            alignItems: 'center',
            cursor: 'pointer',
            fontSize: 18,
            padding: 8,
            borderColor: palette.divider,
            backgroundColor: window.innerWidth < 960 ? alpha(palette.secondary.dark, .1) : alpha(palette.primary.contrastText, .1),
            borderRadius: 50,
            color: window.innerWidth < 960 ? palette.secondary.dark : palette.white.main,
            position: 'relative',
            '&:after': {
                content: '""',
                display: 'block',
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                zIndex: 0,
                backgroundColor: alpha(palette.secondary.main, .2),
                borderRadius: 40,
                transition: '0.3s cubic-bezier(.47,1.64,.41,.8)',
                transform: 'scale(0)'
            },
            '&:not(:first-of-type)': {
                marginLeft: '0.5rem'
            },
            '&:hover, &:focus': {
                color: palette.secondary.main,
                '&:after': {
                    transform: 'scale(1)'
                }
            }
        },
        icon: {
            position: 'relative',
            zIndex: 1
        }
    };
});
