import { useState, useEffect } from 'react';
import { Navigation } from './components/navigation';
import { Header } from './components/header';
import { About } from './components/about';
import { Footer } from './components/footer';
import { Contact } from './components/contact';
import { Sell } from './components/sell';
import JsonData from './data/data.json';
import SmoothScroll from 'smooth-scroll';
import { createTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import { ParallaxProvider } from 'react-scroll-parallax';

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

const theme = createTheme({
  palette: {
    primary: {
      main: '#272c34',
      contrastText: '#fff',
      light: '#b7ec59'
    },
    secondary: {
      main: '#97de1a',
      contrastText: '#272c34',
      dark: '#66CC33'
    },
    error: {
      main: '#fc9e11',
      contrastText: 'white'
    },
    white: {
      main: '#fff'
    },
    text: {
        main: '#777'
    }
  },
});

const App = () => {
  const [landingPageData, setLandingPageData] = useState({});

  useEffect(() => {
    setLandingPageData(JsonData);
  }, [landingPageData]);

  return (
    <ParallaxProvider>
      <ThemeProvider theme={theme}>
        <Navigation data={landingPageData.Global} />
        <Header data={landingPageData.Header} />
        <Sell data={landingPageData.Header} />
        <About data={landingPageData.About} />
        <Contact data={landingPageData.Contact} />
        <Footer data={landingPageData.Contact} logoSrc='img/logo.svg' />
      </ThemeProvider>
    </ParallaxProvider>
  );
};

export default App;
