import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import emailjs from 'emailjs-com';
import { useState } from 'react';
import JsonData from '../data/data.json';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import EmojiEventsTwoToneIcon from '@material-ui/icons/EmojiEventsTwoTone';

const styles = makeStyles((theme) => ({
    root: {
        padding: '100px 0 60px 0',
        background: '#f6f6f6',
        color: theme.palette.text.main,
        '& .section-title': {
            marginBottom: 40,
            '& p': {
                fontSize: 16,
            },
            '& h2::after': {
                position: 'absolute',
                content: '""',
                background: 'linear-gradient(to right, #66CC33 0%, #97DE1A 100%)',
                height: '4px',
                width: '60px',
                bottom: '0',
                left: '30px',
            }
        },
        '& h2': {
            marginTop: 10,
            marginBottom: 15,
            paddingBottom: 15,
        },
        '& .btn-custom': {
            cursor: 'pointer'
        },
    },
    icon: {
        fontSize: 80,
        color: theme.palette.secondary.main
    },
    sideCenter: {
        textAlign: 'center'
    },
    justify: {
        textAlign: 'justify'
    }
}));

const formStyles = makeStyles((theme) => ({
    root: {
        '& label.MuiFormLabel-root.MuiInputLabel-shrink': {
            color: theme.palette.secondary.main,
            background: 'rgba(0,0,0,.8)',
            padding: '4px 8px',
            borderRadius: 20,
            transform: 'translate(10px, -10px) scale(0.75)',
            '&.Mui-error': { color: theme.palette.error.main, }
        },
        '& .MuiTextField-root': {
            width: '100%',
            '& .MuiInputBase-root': {
                background: 'white',
                '& textarea': { padding: 0 }
            },
            '& .MuiInputBase-root.Mui-disabled': { background: 'rgba(144, 144, 144, 1)' },
            '& .MuiFormHelperText-root:not(.Mui-error)': { color: '#97de1a' }
        },
        '& .MuiFormControl-root': {
            '& .MuiInputBase-input': { padding: '12px' },
            '& .MuiFormHelperText-root:not(.Mui-error)': { color: '#97de1a' },
            '& .MuiInputLabel-outlined:not(.Mui-focused):not(.MuiFormLabel-filled)': {
                transform: 'translate(14px, 14px) scale(1)'
            }
        },
        '& .MuiAutocomplete-root': {
            '& .MuiInputBase-input': {
                padding: '4px!important'
            }
        },
        '& .MuiFormGroup-root + .MuiFormHelperText-root': {
            marginTop: -12
        },
    },
    contactInfoH3: {
        color: theme.palette.white.main,
        marginTop: 94,
        marginBottom: 25,
        paddingBottom: 20
    },
    textField: {
        '& label.Mui-focused': { // Focused color
            color: theme.palette.secondary.dark,
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: theme.palette.secondary.dark,
        },
        '& .MuiOutlinedInput-root': { // Default border color
            '& fieldset': {
                // borderColor: 'red',
            },
            '&:hover fieldset': { // Hover color
                borderColor: theme.palette.secondary.main,
            },
            '&.Mui-focused fieldset': {
                borderColor: theme.palette.secondary.dark,
            },
        }
    }
}));

const initialState = {
    from_name: '',
    reply_to: '',
    message: '',
};

export const Sell = (props) => {
    const [{ from_name, reply_to, message }, setState] = useState(initialState);
    const classes = formStyles();
    const style = styles();

    const handleChange = (e) => {
        const { name, value } = e.target;
        setState((prevState) => ({ ...prevState, [name]: value }));
    };
    const clearState = () => setState({ ...initialState });

    const handleSubmit = (e) => {
        e.preventDefault();
        let { SERVICE_ID, TEMPLATE_ID, USER_ID } = JsonData.Contact.credentials;

        emailjs.send(SERVICE_ID, TEMPLATE_ID, { to_name: 'AFPAuto.com', from_name, message, reply_to, }, USER_ID)
            .then(
                (result) => {
                    console.log(result.text);
                    clearState();
                },
                (error) => {
                    console.log(error.text);
                }
            );
    };
    return (
        <div id='sell' className={style.root}>
            <Container maxWidth="lg">
                <Grid container justifyContent="space-between">

                    <Grid item xs={12} md={7}>
                        <div className='section-title'>
                            <h2>Sell us your car</h2>
                            <p>
                                Ready to put your car on the market? Let us help! <br />
                                We'll handle all the paperwork and filter out the fraudsters to find you the best possible buyer.
                            </p>
                            <p>Let us know what type of car you have, and we'll send you a quote.</p>
                        </div>

                        <Grid container className={classes.root}>
                            <Grid item xs={12}>
                                <form
                                    className={classes.textField}
                                    name='sentMessage'
                                    validate="validate"
                                    onSubmit={handleSubmit}>

                                    <Grid container spacing={3}>
                                        <Grid item xs={12} md={6}>
                                            <TextField
                                                type='text'
                                                id='from_name'
                                                name='from_name'
                                                className='form-control'
                                                placeholder='Name'
                                                variant="outlined"
                                                required
                                                onChange={handleChange}
                                                value={from_name}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <TextField
                                                type='email'
                                                id='reply_to'
                                                name='reply_to'
                                                className='form-control'
                                                placeholder='Email'
                                                variant="outlined"
                                                required
                                                onChange={handleChange}
                                                value={reply_to}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                name='message'
                                                id='message'
                                                className='form-control'
                                                placeholder='Message'
                                                required
                                                minRows={4}
                                                onChange={handleChange}
                                                variant="outlined"
                                                multiline
                                                value={message}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <div id='success'></div>
                                            <button type='submit' className='btn btn-custom btn-lg'>
                                                Send Message
                                            </button>
                                        </Grid>
                                    </Grid>
                                </form>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={12} md={4}>
                        <div className={style.sideCenter}>
                            <h1><EmojiEventsTwoToneIcon className={style.icon} /></h1>
                            <h3>Why choose us?</h3>
                            <p className={style.justify}>
                                We do the heavy lifting and save you time when selling your used car in Houston.
                                <p>No trips to the DMV.</p>
                                <p>No briefcases full of cash.</p>
                                <p>We are experts in how to sell cars privately and have simplified the process to make it easy and stress-free so you can get paid fast.
                                </p>
                            </p>

                        </div>
                    </Grid>

                </Grid>
            </Container>
        </div>
    );
};
