import React from 'react';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { Policy } from './policies';
import swal from '@sweetalert/with-react';
import {
  makeStyles,
  // createTheme
} from '@material-ui/core/styles';
import { ColumnToRow, Item } from '@mui-treasury/components/flex';
import { NavMenu, NavItem } from '@mui-treasury/components/menu/navigation';
import { CategoryProvider, CategoryTitle, CategoryItem } from '@mui-treasury/components/menu/category';
import { SocialProvider, SocialLink } from '@mui-treasury/components/socialLink';
import { useMagCategoryMenuStyles } from '@mui-treasury/styles/categoryMenu/mag';
import { usePoofSocialLinkStyles } from '@mui-treasury/styles/socialLink/poof';
import { usePlainNavigationMenuStyles } from '@mui-treasury/styles/navigationMenu/plain';

// const darkTheme = createTheme({ palette: { type: 'dark' } });

const useStyles = makeStyles(({ palette, typography }) => ({
  top: {
    backgroundSize: 'cover',
    overflow: 'hidden',
  },
  middle: {
    backgroundColor: palette.type === 'dark' ? '#192D36' : palette.action.hover,
  },
  bottom: {
    backgroundColor:
      palette.type === 'dark' ? '#0F2128' : palette.action.selected,
  },
  newsletterText: {
    color: '#fff',
    fontSize: '0.875rem',
    textTransform: 'uppercase',
  },
  form: {
    margin: 0,
    minWidth: 343,
    fontSize: '0.875rem',
  },
  legalLink: {
    textTransform: 'uppercase',
    fontWeight: 'bold',
    fontSize: '0.75rem',
    justifyContent: 'center',
    color: palette.text.hint,
    letterSpacing: '0.5px',
  },
  divider: {
    height: 2,
    margin: '-1px 0',
  },
  overlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    filter: 'grayscale(80%)',
    '& img': {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
    },
  },
  info: {
    ...typography.caption,
    color: palette.text.hint,
    marginTop: 8,
  },
  icon: { background: 'rgba(0, 0, 0, 0.08)', borderRadius: 50 }
}));

export const Footer = React.memo(function ArcAppFooter(props) {
  const classes = useStyles();

  const handleModal = (e) => {
    swal({
      content: <Policy policy={e.target.id} />,
      button: 'OK',
    });
  };

  return (

    <Box width={'100%'}>
      <Box px={2} py={10} className={classes.middle}>
        <Container disableGutters>
          <Grid container spacing={4}>
            <Grid item xs={12} md={4} lg={3}>
              <Box
                component={'img'}
                mt={-3}
                width={120}
                height={64}
                src={props.logoSrc}
                alt=""
                borderRadius={12}
              />
              <Typography className={classes.info}>

                {props.data ? props.data.address : 'loading'}

              </Typography>

              <Typography className={classes.info}>

                {props.data ? props.data.email : 'loading'}

              </Typography>
            </Grid>
            <Grid item xs={12} md={8} lg={6}>
              <Grid container spacing={2}>

                <Grid item xs={6} sm={6}>
                  <CategoryProvider useStyles={useMagCategoryMenuStyles}>
                    <CategoryTitle>
                      Information
                    </CategoryTitle>
                    <CategoryItem id="returns" onClick={handleModal}>
                      Return Policy
                    </CategoryItem>
                    <CategoryItem id="privacy" onClick={handleModal}>
                      Privacy Policy
                    </CategoryItem>
                    <CategoryItem id="shipping" onClick={handleModal}>
                      Shipping Policy
                    </CategoryItem>
                    <CategoryItem id="terms" onClick={handleModal}>
                      Terms & Conditions
                    </CategoryItem>
                  </CategoryProvider>
                </Grid>
                <Grid item xs={6} sm={6}>
                  <CategoryProvider useStyles={useMagCategoryMenuStyles}>
                    <CategoryTitle>
                      About
                    </CategoryTitle>
                    <CategoryItem href="#contact">
                      Contact
                    </CategoryItem>
                  </CategoryProvider>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={8} lg={3} style={{ marginLeft: 'auto' }}>
              <CategoryProvider useStyles={useMagCategoryMenuStyles}>
                <CategoryTitle>
                  Subscribe
                </CategoryTitle>
              </CategoryProvider>
              <SocialProvider useStyles={usePoofSocialLinkStyles}>
                <SocialLink brand={'Facebook'} className={classes.icon} href="https://www.facebook.com/4starautoparts" />
                <SocialLink brand={'Envelope'} className={classes.icon} href="#contact" />
                <SocialLink brand={'Ebay'} className={classes.icon} href="https://www.ebay.com/usr/allforeignparts" />
              </SocialProvider>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Box px={2} py={3} className={classes.bottom}>
        <Container disableGutters>
          <ColumnToRow
            at={'md'}
            columnStyle={{ alignItems: 'center' }}
            rowStyle={{ alignItems: 'unset' }}
          >
            <Item grow ml={-2} shrink={0}>
              <NavMenu useStyles={usePlainNavigationMenuStyles}>
                <ColumnToRow at={'sm'}>
                  <NavItem className={classes.legalLink} id="terms" onClick={handleModal}>
                    Terms & Conditions
                  </NavItem>
                  <NavItem className={classes.legalLink} id="privacy" onClick={handleModal}>
                    Privacy Policy
                  </NavItem>
                  <NavItem className={classes.legalLink} id="shipping" onClick={handleModal}>
                    Shipping Policy
                  </NavItem>
                  <NavItem className={classes.legalLink} id="returns" onClick={handleModal}>
                    Return Policy
                  </NavItem>
                </ColumnToRow>
              </NavMenu>
            </Item>
            <Item>
              <Box py={1} textAlign={{ xs: 'center', md: 'right' }}>
                <Typography
                  component={'p'}
                  variant={'caption'}
                  color={'textSecondary'}
                >

                  &copy; 2021 Mitchell & Valad Consulting. Design by{' '}
                  <a href='http://www.null-terminator.com/' rel='nofollow'>
                    Null-Terminator
                  </a>, Mitchell & Valad Consulting

                </Typography>
              </Box>
            </Item>
          </ColumnToRow>
        </Container>
      </Box>
    </Box>
  );
});