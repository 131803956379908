import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';

export const About = (props) => {
  return (
    <Container maxWidth="lg">
      <div id="about">
        <Grid container spacing={4}>
          <Grid item xs={12} md={6}><img src='img/about.jpg' className='img-responsive' alt='' />{' '}</Grid>
          <Grid item xs={12} md={6}>
            <div className='about-text'>
              <h2>About Us</h2>
              <p>{props.data ? props.data.paragraph : 'loading...'}</p>
              <h3>Why Choose Us?</h3>
              <div className='list-style'>
                <Grid container>
                  <Grid item xs={12} sm={6}>
                    <ul>
                      {props.data
                        ? props.data.Why.map((d, i) => (
                          <li key={`${d}-${i}`}>{d}</li>
                        ))
                        : 'loading'}
                    </ul>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <ul>
                      {props.data
                        ? props.data.Why2.map((d, i) => (
                          <li key={`${d}-${i}`}> {d}</li>
                        ))
                        : 'loading'}
                    </ul>
                  </Grid>
                </Grid>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    </Container>

  );
};
