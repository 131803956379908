export const SubscribeEmail = (email) => {
  return `
  <body style="box-sizing: border-box;">
  <table class="tRoot" style="box-sizing: border-box; width: 100%; height: 100%; background: #f0f1f3;" width="100%"
    height="100%">
    <tbody style="box-sizing: border-box;">
      <tr style="box-sizing: border-box;">
        <td class="skinny" style="box-sizing: border-box; padding: 0px; margin: 0px;">
          <table class="tSubRoot" style="box-sizing: border-box; width: 75%; height: 100%; margin: 0px auto 0px auto;"
            width="75%" height="100%">
            <tbody style="box-sizing: border-box;">
              <tr style="box-sizing: border-box;">
                <td class="skinny" style="box-sizing: border-box; padding: 0px; margin: 0px;">
                  <table class="tContainer"
                    style="box-sizing: border-box; width: 100%; height: 100%; font-family: 'Roboto', sans-serif; border-collapse: collapse;"
                    width="100%" height="100%">
                    <tbody class="tBody" style="box-sizing: border-box; margin: 0px 24px;">
                      <tr class="tLogoRow center" style="box-sizing: border-box; text-align: center; height: 120px;"
                        align="center">
                        <td colspan="2" style="box-sizing: border-box; padding: 40px 0 10px 0; text-align: left;">
                          <img
                            src="https://mcusercontent.com/2b8261836e351cc7f07b64915/images/601fa1c1-a34d-6c88-847b-ce74ceb53361.png"
                            style="box-sizing: border-box; width: 150px; height: auto;">
                        </td>
                        <td id="social" colspan="1" style="vertical-align: bottom;">


                          <table border="0" cellpadding="0" cellspacing="0" width="100%"
                            style="min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;"
                            class="mcnFollowContent">
                            <tbody>
                              <tr>
                                <td align="right" valign="top"
                                  style="padding-top: 9px;padding-right: 9px;padding-left: 9px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                                  <table align="right" border="0" cellpadding="0" cellspacing="0"
                                    style="border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                                    <tbody>
                                      <tr>
                                        <td align="center" valign="top"
                                          style="mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%; padding-bottom: 4px;">
                                          <table align="left" border="0" cellpadding="0" cellspacing="0"
                                            class="mcnFollowStacked"
                                            style="display: inline;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">

                                            <tbody>
                                              <tr>
                                                <td align="center" valign="top" class="mcnFollowIconContent"
                                                  style="padding-right: 10px;padding-bottom: 9px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                                                  <a href="https://www.facebook.com/4starautoparts" target="_blank"
                                                    style="mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;"><img
                                                      src="https://cdn-images.mailchimp.com/icons/social-block-v2/dark-facebook-96.png"
                                                      alt="Facebook" class="mcnFollowBlockIcon" width="48"
                                                      style="width: 48px;max-width: 48px;display: block;border: 0;height: auto;outline: none;text-decoration: none;-ms-interpolation-mode: bicubic;"></a>
                                                </td>
                                              </tr>


                                            </tbody>
                                          </table>

                                          <table align="left" border="0" cellpadding="0" cellspacing="0"
                                            class="mcnFollowStacked"
                                            style="display: inline;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">

                                            <tbody>
                                              <tr>
                                                <td align="center" valign="top" class="mcnFollowIconContent"
                                                  style="padding-right: 10px;padding-bottom: 9px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                                                  <a href="mailto:support@afpauto.com" target="_blank"
                                                    style="mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;"><img
                                                      src="https://cdn-images.mailchimp.com/icons/social-block-v2/dark-forwardtofriend-96.png"
                                                      alt="Email support" class="mcnFollowBlockIcon" width="48"
                                                      style="width: 48px;max-width: 48px;display: block;border: 0;height: auto;outline: none;text-decoration: none;-ms-interpolation-mode: bicubic;"></a>
                                                </td>
                                              </tr>


                                            </tbody>
                                          </table>

                                          <table align="left" border="0" cellpadding="0" cellspacing="0"
                                            class="mcnFollowStacked"
                                            style="display: inline;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">

                                            <tbody>
                                              <tr>
                                                <td align="center" valign="top" class="mcnFollowIconContent"
                                                  style="padding-right: 0;padding-bottom: 9px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                                                  <a href="https://www.ebay.com/usr/allforeignparts" target="_blank"
                                                    style="mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;"><img
                                                      src="https://cdn-images.mailchimp.com/icons/social-block-v2/dark-link-96.png"
                                                      alt="Ebay" class="mcnFollowBlockIcon" width="48"
                                                      style="width: 48px;max-width: 48px;display: block;border: 0;height: auto;outline: none;text-decoration: none;-ms-interpolation-mode: bicubic;"></a>
                                                </td>
                                              </tr>


                                            </tbody>
                                          </table>

                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </td>
                              </tr>
                            </tbody>
                          </table>



                        </td>
                      </tr>
                      <tr class="accentBg"
                        style="box-sizing: border-box; background: linear-gradient(to right, #66CC33 0%, #97DE1A 100%);">
                        <td class="tHeader title v-bottom" colspan="2"
                          style="box-sizing: border-box;padding: 48px;height: 80px;color: white;font-size: 60px;font-weight: 500;padding-bottom: 0px;vertical-align: bottom;letter-spacing: 1px;"
                          height="80" valign="bottom">
                          <span style="box-sizing: border-box;">Welcome</span>
                        </td>
                        <td class="headerImg" rowspan="2"
                          style="box-sizing: content-box;padding: 48px 24px;text-align: right;position: relative;background: url(	https://mcusercontent.com/2b8261836e351cc7f07b64915/images/117687bd-e063-3283-a816-f8f20bfa7db7.png);background-size: 450px;background-repeat: no-repeat;background-position: 20px -120px; background-color: #87d822;">
                        </td>
                      </tr>
                      <tr class="accentBg"
                        style="box-sizing: border-box; background: linear-gradient(to right, #66CC33 0%, #97DE1A 100%);">
                        <td class="subtitle"
                          style="box-sizing: border-box;padding: 12px 48px 48px 48px;color: white;font-size: 18px;font-weight: 400;height: 50px;vertical-align: middle;text-transform: uppercase; letter-spacing: 1.5px;"
                          height="70" valign="top">Thank you for subscribing!</td>
                      </tr>
                      <tr class="tRow" style="box-sizing: border-box; padding: 8px; background: white;">
                        <td class="v-top" colspan="3"
                          style="box-sizing: border-box; padding: 48px; vertical-align: top;" valign="top">
                          <h2>We appreciate your business!</h2>
                          <p>Keep an eye out for exclusive offers and deals. </p>
                          <p>You can also search for parts by visiting the site!</p>


                          <table border="0" cellpadding="0" cellspacing="0" class="mcnButtonContentContainer"
                            style="margin-top: 52px; border-collapse: separate !important;border-radius: 3px;background-color: #97DE1A;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                            <tbody>
                              <tr>
                                <td align="center" valign="middle" class="mcnButtonContent"
                                  style="font-family: Helvetica;font-size: 18px;padding: 18px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                                  <a class="mcnButton " title="Visit AFPAuto.com" href="https://www.afpauto.com"
                                    target="_blank"
                                    style="font-weight: bold;letter-spacing: -0.5px;line-height: 100%;text-align: center;text-decoration: none;color: #FFFFFF;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;display: block;">Visit
                                    AFPAuto.com</a>
                                </td>
                              </tr>
                            </tbody>
                          </table>



                        </td>
                      </tr>
                      <tr class="tRow center"
                        style="box-sizing: border-box; text-align: center; padding: 8px; background: white;"
                        align="center">
                        <td class="footer" colspan="3"
                          style="box-sizing: border-box; padding: 0px; background: #272c34; color: white; font-weight: 200; font-size: 11px; height: 50px;"
                          height="50">
                          <table align="center" border="0" cellpadding="0" cellspacing="0" width="100%"
                            class="templateContainer"
                            style="border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;max-width: 600px !important;">
                            <tbody>
                              <tr>
                                <td valign="top" class="footerContainer"
                                  style="background:transparent none no-repeat center/cover;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;background-color: transparent;background-image: none;background-repeat: no-repeat;background-position: center;background-size: cover;border-top: 0;border-bottom: 0;padding-top: 0;padding-bottom: 0;">
                                  <table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnFollowBlock"
                                    style="min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                                    <tbody class="mcnFollowBlockOuter">
                                      <tr>
                                        <td align="center" valign="top"
                                          style="padding: 9px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;"
                                          class="mcnFollowBlockInner">
                                          <table border="0" cellpadding="0" cellspacing="0" width="100%"
                                            class="mcnFollowContentContainer"
                                            style="min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                                            <tbody>
                                              <tr>
                                                <td align="center"
                                                  style="padding-left: 9px;padding-right: 9px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                                                  <table border="0" cellpadding="0" cellspacing="0" width="100%"
                                                    style="min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;"
                                                    class="mcnFollowContent">
                                                    <tbody>
                                                      <tr>
                                                        <td align="center" valign="top"
                                                          style="padding-top: 9px;padding-right: 9px;padding-left: 9px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                                                          <table align="center" border="0" cellpadding="0"
                                                            cellspacing="0"
                                                            style="border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                                                            <tbody>
                                                              <tr>
                                                                <td align="center" valign="top"
                                                                  style="mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                                                                  <table align="left" border="0" cellpadding="0"
                                                                    cellspacing="0"
                                                                    style="display: inline;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                                                                    <tbody>
                                                                      <tr>
                                                                        <td valign="top"
                                                                          style="padding-right: 10px;padding-bottom: 9px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;"
                                                                          class="mcnFollowContentItemContainer">
                                                                          <table border="0" cellpadding="0"
                                                                            cellspacing="0" width="100%"
                                                                            class="mcnFollowContentItem"
                                                                            style="border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                                                                            <tbody>
                                                                              <tr>
                                                                                <td align="left" valign="middle"
                                                                                  style="padding-top: 5px;padding-right: 10px;padding-bottom: 5px;padding-left: 9px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                                                                                  <table align="left" border="0"
                                                                                    cellpadding="0" cellspacing="0"
                                                                                    width=""
                                                                                    style="border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                                                                                    <tbody>
                                                                                      <tr>

                                                                                        <td align="center"
                                                                                          valign="middle" width="24"
                                                                                          class="mcnFollowIconContent"
                                                                                          style="mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                                                                                          <a href="https://www.facebook.com/4starautoparts"
                                                                                            target="_blank"
                                                                                            style="mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;"><img
                                                                                              src="https://cdn-images.mailchimp.com/icons/social-block-v2/outline-light-facebook-48.png"
                                                                                              alt="Facebook"
                                                                                              style="display: block;border: 0;height: auto;outline: none;text-decoration: none;-ms-interpolation-mode: bicubic;"
                                                                                              height="24" width="24"
                                                                                              class=""></a>
                                                                                        </td>


                                                                                      </tr>
                                                                                    </tbody>
                                                                                  </table>
                                                                                </td>
                                                                              </tr>
                                                                            </tbody>
                                                                          </table>
                                                                        </td>
                                                                      </tr>
                                                                    </tbody>
                                                                  </table>
                                                                  <table align="left" border="0" cellpadding="0"
                                                                    cellspacing="0"
                                                                    style="display: inline;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                                                                    <tbody>
                                                                      <tr>
                                                                        <td valign="top"
                                                                          style="padding-right: 10px;padding-bottom: 9px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;"
                                                                          class="mcnFollowContentItemContainer">
                                                                          <table border="0" cellpadding="0"
                                                                            cellspacing="0" width="100%"
                                                                            class="mcnFollowContentItem"
                                                                            style="border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                                                                            <tbody>
                                                                              <tr>
                                                                                <td align="left" valign="middle"
                                                                                  style="padding-top: 5px;padding-right: 10px;padding-bottom: 5px;padding-left: 9px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                                                                                  <table align="left" border="0"
                                                                                    cellpadding="0" cellspacing="0"
                                                                                    width=""
                                                                                    style="border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                                                                                    <tbody>
                                                                                      <tr>

                                                                                        <td align="center"
                                                                                          valign="middle" width="24"
                                                                                          class="mcnFollowIconContent"
                                                                                          style="mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                                                                                          <a href="mailto:support@afpauto.com"
                                                                                            target="_blank"
                                                                                            style="mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;"><img
                                                                                              src="https://cdn-images.mailchimp.com/icons/social-block-v2/outline-light-forwardtofriend-48.png"
                                                                                              alt="Email support"
                                                                                              style="display: block;border: 0;height: auto;outline: none;text-decoration: none;-ms-interpolation-mode: bicubic;"
                                                                                              height="24" width="24"
                                                                                              class=""></a>
                                                                                        </td>


                                                                                      </tr>
                                                                                    </tbody>
                                                                                  </table>
                                                                                </td>
                                                                              </tr>
                                                                            </tbody>
                                                                          </table>
                                                                        </td>
                                                                      </tr>
                                                                    </tbody>
                                                                  </table>
                                                                  <table align="left" border="0" cellpadding="0"
                                                                    cellspacing="0"
                                                                    style="display: inline;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                                                                    <tbody>
                                                                      <tr>
                                                                        <td valign="top"
                                                                          style="padding-right: 0;padding-bottom: 9px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;"
                                                                          class="mcnFollowContentItemContainer">
                                                                          <table border="0" cellpadding="0"
                                                                            cellspacing="0" width="100%"
                                                                            class="mcnFollowContentItem"
                                                                            style="border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                                                                            <tbody>
                                                                              <tr>
                                                                                <td align="left" valign="middle"
                                                                                  style="padding-top: 5px;padding-right: 10px;padding-bottom: 5px;padding-left: 9px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                                                                                  <table align="left" border="0"
                                                                                    cellpadding="0" cellspacing="0"
                                                                                    width=""
                                                                                    style="border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                                                                                    <tbody>
                                                                                      <tr>

                                                                                        <td align="center"
                                                                                          valign="middle" width="24"
                                                                                          class="mcnFollowIconContent"
                                                                                          style="mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                                                                                          <a href="https://www.ebay.com/usr/allforeignparts"
                                                                                            target="_blank"
                                                                                            style="mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;"><img
                                                                                              src="https://cdn-images.mailchimp.com/icons/social-block-v2/outline-light-link-48.png"
                                                                                              alt="Ebay"
                                                                                              style="display: block;border: 0;height: auto;outline: none;text-decoration: none;-ms-interpolation-mode: bicubic;"
                                                                                              height="24" width="24"
                                                                                              class=""></a>
                                                                                        </td>


                                                                                      </tr>
                                                                                    </tbody>
                                                                                  </table>
                                                                                </td>
                                                                              </tr>
                                                                            </tbody>
                                                                          </table>
                                                                        </td>
                                                                      </tr>
                                                                    </tbody>
                                                                  </table>
                                                                </td>
                                                              </tr>
                                                            </tbody>
                                                          </table>
                                                        </td>
                                                      </tr>
                                                    </tbody>
                                                  </table>
                                                </td>
                                              </tr>
                                            </tbody>
                                          </table>

                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                  <table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnDividerBlock"
                                    style="min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;table-layout: fixed !important;">
                                    <tbody class="mcnDividerBlockOuter">
                                      <tr>
                                        <td class="mcnDividerBlockInner"
                                          style="min-width: 100%;padding: 0 18px 18px 18px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                                          <table class="mcnDividerContent" border="0" cellpadding="0" cellspacing="0"
                                            width="100%"
                                            style="min-width: 100%;border-top: 2px solid #505050;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                                            <tbody>
                                              <tr>
                                                <td
                                                  style="mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                                                  <span></span>
                                                </td>
                                              </tr>
                                            </tbody>
                                          </table>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                  <table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnTextBlock"
                                    style="min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                                    <tbody class="mcnTextBlockOuter">
                                      <tr>
                                        <td valign="top" class="mcnTextBlockInner"
                                          style="padding-top: 9px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                                          <table align="left" border="0" cellpadding="0" cellspacing="0"
                                            style="max-width: 100%;min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;"
                                            width="100%" class="mcnTextContentContainer">
                                            <tbody>
                                              <tr>

                                                <td valign="top" class="mcnTextContent"
                                                  style="padding-top: 0;padding-right: 18px;padding-bottom: 9px;padding-left: 18px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;word-break: break-word;color: #FFFFFF;font-family: Roboto;font-size: 12px;line-height: 150%;text-align: center;">

                                                  <em>Copyright ©2021 AFPAuto.com, All rights
                                                    reserved.</em>
                                                  <br>
                                                  <br>
                                                  <strong>Our mailing address is:</strong>
                                                  <br>
                                                  9300 Airline Dr, Houston, TX 77037
                                                  <br>
                                                  <br>
                                                  No longer want to receive emails?<br>
                                                  You can <a href="${window.location.protocol}//${window.location.hostname}${window.location.hostname === 'localhost' ? ':9100' : ''}/api/unsubscribe/${email}"
                                                  style="mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;color: #FFFFFF;font-weight: normal;text-decoration: underline;">unsubscribe
                                                  from this list</a>.
                                                  <br>
                                                  <br>
                                                </td>
                                              </tr>
                                            </tbody>
                                          </table>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>

                      <tr style="box-sizing: border-box;">
                        <td class="spacer" style="box-sizing: border-box; padding: 0; height: 24px; margin: 0;"
                          height="24"></td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            </tbody>
          </table>
        </td>
      </tr>
    </tbody>
  </table>
</body>`;
};